@import '../../scss/tsavariables';

.help-header {
  background-color: $blue-dark;
  color: $white;
  font-family: $font-family-light;
  font-size: 2rem;
  padding: 1rem 2rem 1rem 2rem;

  .title {
    margin-top: 0.5rem;
  }
}
