@import '../../scss/tsavariables';

.question-help {
  display: flex;
  padding: 0 0.5rem;
  margin-bottom: 2rem;
  font-size: 0.75rem;
  background-color: $white-smoke;
  .icon {
    fill: $gray-icon;
    height: 2rem;
    min-width: 2rem;
    margin-right: 0.5rem;
  }
  .simple-link {
    display: inline-block;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  .question-help-text {
    max-height: 6.25rem;
    overflow-y: hidden;
  }
  &.read-more {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    margin: 0;
    z-index: $two-z-index;
    .question-help-text {
      max-height: inherit;
    }
    .simple-link {
      margin-top: 0;
      margin-bottom: 2rem;
    }
  }
}
