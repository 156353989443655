@import '~bootstrap/scss/mixins';
@import '../../scss/tsamixins';
@import '../../scss/tsavariables';
$comment-component-font-size: 0.875rem;
.comment-component {
  display: block;
  flex-direction: column;
  background-color: $table-cell-color;
  padding: 0.5rem 0;
  width: 100%;
  font-size: $comment-component-font-size;

  .xl-text {
    display: none;
  }

  @include media-breakpoint-up(xl) {
    .xl-text {
      display: inline;
    }
  }
}

.comment-component-label,
.comment-component-notification-dropdown,
.comment-dropdown {
  display: flex;
  align-items: center;
}

.comment-component-label {
  justify-content: space-between;
  margin-bottom: 0.25rem;
}

.comment-component-label {
  color: $white;
  padding-left: 0.75rem;
  padding-right: 2.75rem;
}

.comment-dropdown {
  .btn {
    color: $white;
    background-color: transparent;
    align-items: center;
    font-size: $comment-component-font-size;
  }
  .btn-label {
    padding: 0 0.5rem;
  }
  .btn-toggle {
    border-left: 0;
    padding: 0;
  }
  .dropdown-menu {
    position: absolute;
    right: 0px;
    left: auto;
  }
}

.comment-component-input {
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  .icon {
    cursor: pointer;
    font-size: 1.75rem;
  }
  .icon.attachment {
    fill: $blue;
  }
}

.comment-input {
  flex: 1 1 auto;
  background-color: $white-smoke;
  display: flex;
  align-items: center;
  border: 1px solid $gray;
  border-radius: 0.4rem;
  font-size: 0.75rem;
  textarea {
    flex: 1 1 auto;
    background-color: transparent;
    padding: 0.5rem;
    border: 0;
    outline: none;
    overflow: hidden !important;
    resize: none;
    &::placeholder {
      opacity: 0.8;
      @include font-family-italics();
    }
  }
}

.comment-save {
  color: $white;
  margin: 0 0.5rem;
}
