@import "../../scss/tsavariables";
@import "../../scss/tsamixins";
@import "~bootstrap/scss/mixins";
.summary-wrapper {
  transition: all 0.1s linear;
  margin-bottom: 1rem;
  line-height: 1.3;
}

.summary {
  display: flex;
  flex-direction: column;
  .rdtPicker {
    position: relative;
  }

  .summary-label {
    align-items: flex-start;
    background-color: $white-smoke;
    border-radius: 0.25rem;
    border: 0.063rem solid $gray-iron;
    cursor: pointer;
    display: flex;
    min-height: 3rem;
    padding-bottom: 0.3125rem;
    padding-top: 0.3125rem;
    position: relative;
    transition: border-bottom 0.5s ease;

    .checkbox label::before {
      top: -1rem;
    }

    .form-check-input {
      z-index: -999;
    }

    svg {
      cursor: pointer;
    }

    .summary-main {
      width: 100%;
    }
    .open-close-container {
      flex-shrink: 0;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
      &.hide {
        opacity: 0;
      }
    }
    .summary-icon-container {
      display: none;
      flex-shrink: 0;
    }
    .summary-center-wrapper {
      padding-left: 0.125rem;
      padding-right: 0.125rem;
      display: inline-flex;
      flex: 1 1;
      flex-direction: column;
      min-width: 10rem;
      width: 100%;
      .summary-label-wrapper {
        display: inline-flex;
        align-items: flex-start;
        flex-direction: column;
        max-width: 100%;
        .summary-label-content {
          @include text-one-line-ellipsis-truncation;
          flex-grow: 0;
          flex-shrink: 0;
          padding-left: 0.75rem;
        }
        .summary-label-divider {
          display: none;
          padding-left: 0.3rem;
          padding-right: 0.3rem;

          &.second {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
        }
        .summary-label-secondary-label {
          flex-shrink: 0;
          flex-grow: 0;
        }
        .summary-label-hint {
          @include text-one-line-ellipsis-truncation;
          color: $gray;
          margin-right: auto;
          padding-left: 0.75rem;
          width: 100%;
          font-size: 0.75rem;
        }
      }
      .summary-detail-wrapper {
        span {
          @include text-one-line-ellipsis-truncation;
        }
        display: flex;
        flex-wrap: wrap;
        min-width: 0;
        flex-grow: 1;
        padding-left: 0.75rem;
        .click {
          margin-right: 0.8rem;
          font-size: 0.75rem;
        }
        .click,
        &.click {
          color: $blue-dark;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .select-container {
      height: 1rem;
      width: 1rem;
    }

    .summary-action-icons {
      display: flex;
      flex-shrink: 0;
      margin-left: auto;
      padding-right: 0.25rem;
      right: 0;
      top: 0;

      .icon-container {
        padding-left: 0.353rem;
        padding-right: 0.353rem;

        .icon {
          height: 1.125rem;
          width: 1.125rem;
        }

        &.click {
          cursor: pointer;
        }
      }
    }
    .summary-icon {
      height: 1.5rem;
      width: 1.5rem;
      stroke: $blue;
      fill: $blue;
      &.carryForward,
      &.delete,
      &.edit {
        fill: $white;
        &.active {
          fill: $blue;
        }
      }
    }
    .open-icon,
    .close-icon {
      height: 0.8rem;
      width: 0.8rem;
      transform: scale(2);
      stroke: $gray-icon;
      fill: $gray-icon;
      margin-bottom: 0.3rem;
    }
  }
  .summary-details {
    display: flex;
    min-height: 1rem;
    background-color: $white-smoke;
    border-radius: 0.25rem;
    border-left: 0.063rem solid $gray-iron;
    border-right: 0.063rem solid $gray-iron;
    border-bottom: 0.063rem solid $gray-iron;
    margin-right: 0.7rem;

    .summary-details-border {
      display: none;
      background-color: $dark-gray-border;
      width: 0.625rem;
      margin-right: 0.5rem;
    }
    .summary-details-content-container {
      width: 100%;
      margin-left: 0.75rem;
      margin-right: 0.75rem;
    }
    .summary-details-hint,
    .summary-details-content {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .summary-details-hint {
      @include font-family-light();
      font-size: 0.75rem;
      padding-top: 0.75rem;
      padding-left: 2.5rem;
    }
    .question-number {
      display: none;
    }
  }
  &.requires-attention
    > .summary-label
    > .summary-main
    > .summary-center-wrapper {
    @include font-family-bold();
    .summary-detail-wrapper,
    .summary-label-hint {
      @include font-family-base();
    }
    .summary-detail-wrapper.click {
      @include font-family-base();
    }
  }
  &.is-open {
    .summary-label {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-color: rgba(white, 0);
      .summary-center-wrapper {
        .summary-label-wrapper {
          .summary-label-hint {
            white-space: normal;
            text-overflow: unset;
          }
        }
      }
    }
  }
}

// New for file upload
.summary-header {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  border-radius: 0.25rem;
  border: 0.063rem solid $gray-iron;
  background-color: $white-smoke;
  margin-top: 0.75rem;
  padding: 0.375rem 0.75rem;
  min-height: 3rem;

  &.disabled {
    opacity: 0.4;
    background-color: $white;
  }

  .check-boxes.na-checkbox {
    margin: 0;
  }
}

.summary-header .check-box-input label {
  margin-top: 0;
}

.question.selected .summary-header {
  border-color: $blue;
  background-color: $white;
  &.is-invalid {
    border-color: $red;
  }
  &.is-valid {
    border-color: $green;
  }
}

@include media-breakpoint-down(md) {
  .summary .question {
    padding: 0;

    .question-form {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.summary-header-text {
  font-size: 0.875rem;
  flex: 1;
  line-height: 1.1rem;
  margin-right: 0rem;
  width: 75%;
  .file-summary-link {
    color: $blue-dark;
  }
  .file-summary-link:hover {
    color: $blue-medium;
  }
  .file-summary-link,
  span {
    font-size: 0.75rem;
    margin-right: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .summary-header-text-file-count {
    color: $gray;
    font-family: "PreloLight";
    font-weight: normal;
    font-style: normal;
    font-size: 0.688rem;
    letter-spacing: 0.034rem;
    margin: 0.5rem 0;
  }
  .summary-header-files {
    display: flex;
    flex-wrap: wrap;
    .summary-header-files-file {
      width: 15rem;
      margin-right: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .summary-header-files-file button {
      padding: 0;
    }
  }
}

.summary-header-icons {
  white-space: nowrap;
  svg.icon {
    width: 1.25rem;
    height: 1.25rem;
    fill: $blue;
    margin-left: 0.5rem !important;
    &:first-child {
      margin-left: 0;
    }
    &.carryForward,
    &.delete,
    &.edit {
      fill: $blue-disabled;
      cursor: default;
      &.active {
        fill: $blue;
        cursor: pointer;
      }
    }
    &.carryForward {
      cursor: pointer;
    }
  }
}

@include media-breakpoint-up(md) {
  .summary-header-icons svg.icon {
    margin-left: 0rem;
  }
  .summary-wrapper.is-open {
    background-color: $white-smoke;
    border-radius: 0.25rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border: 0.063rem solid $gray-dim;
    border-right: 0;
  }
  .summary {
    &.requires-attention .summary-icon-container {
      color: red;
    }
    .summary-label {
      // flex-wrap: wrap;
      align-items: center;
      .summary-main {
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
      }
      .summary-icon-container {
        display: block;
      }
      .summary-center-wrapper {
        .summary-label-wrapper {
          flex-direction: row;
          align-items: center;
          .summary-label-content {
            padding-left: 0;

            .summary-label-content-required {
              display: none;
            }
          }
          .summary-label-hint {
            font-size: 0.8rem;
            padding-left: 0;
          }
          .summary-label-divider {
            display: block;
          }
        }
        .summary-detail-wrapper {
          padding-left: 0;
        }
      }
      .summary-action-icons {
        position: unset;
        .icon-container {
          padding-top: 0;
          padding-left: 0.47rem;
          padding-right: 0.47rem;
          .icon {
            height: 1.5rem;
            width: 1.5rem;
          }
        }
      }
    }
    .summary-details {
      border: none;
      border-radius: 0;
      border-top: 0.063rem solid #c6cacc;
      .summary-details-border {
        display: block;
      }
    }
    &.is-open {
      .summary-label {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-color: rgba(white, 0);
      }
    }
  }
}

.required-document {
  content: "*";
  position: relative;
  fill: $dark-gray-border;
  stroke: rgba($red, 0);
  height: 0.5rem;
  width: 0.5rem;
  margin-right: 0.5rem;

  &.requires-attention {
    fill: $red;
  }
}

.header-text-blue {
  color: $blue-dark;
}

.file-summary-link {
  background: none;
  border: none;
}
.file-summary-link:hover {
  text-decoration: underline;
}
