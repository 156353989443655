@import "../../scss/tsamixins";
@import "../../scss/tsavariables";
@import "~bootstrap/scss/mixins";

$margin-right: 1rem;
$margin-right-big: 2rem;
$padding-left: 1rem;
$padding-left-big: 2rem;

.document-library {
  @include media-breakpoint-down(lg) {
    padding-left: $padding-left;
  }

  @include media-breakpoint-up(xl) {
    padding-left: $padding-left-big;
  }

  margin-top: 1.9375rem;
  color: $gray-dark;
  letter-spacing: 0.05rem;
  flex-grow: 1;
  width: 100%;
  overflow-x: hidden;
  @include font-family-light();

  .document-library-header {
    @include media-breakpoint-down(lg) {
      margin-right: $margin-right;
    }

    @include media-breakpoint-up(xl) {
      margin-right: $margin-right-big;
    }

    margin-bottom: 2rem;

    .summary {
      font-size: 0.75rem;
    }
    .info {
      display: none;
      color: $gray;
      font-size: 0.75rem;
    }
  }
  .document-library-download-all {
    text-align: right;
    margin-right: 2rem;
  }

  .summary-label {
    @include media-breakpoint-down(lg) {
      margin-right: $margin-right;
    }

    @include media-breakpoint-up(xl) {
      margin-right: $margin-right-big;
    }
  }
  .summary .summary-details {
    @include media-breakpoint-down(lg) {
      margin-right: $margin-right;
    }

    @include media-breakpoint-up(xl) {
      margin-right: $margin-right-big;
    }
  }

  .document-library-legend {
    margin-bottom: 0.75rem;

    .required-document-no-need,
    .required-document-need {
      height: 0.5rem;
      width: 0.5rem;
      stroke: rgba($red, 0);
    }

    .required-document-no-need {
      fill: $dark-gray-border;
    }

    .required-document-need {
      fill: $red;
    }
  }
}

@include media-breakpoint-up(md) {
  .document-library {
    .document-library-header {
      .summary {
        font-size: $font-size-base;
      }
      .info {
        display: block;
      }
    }
    .summary {
      &.is-open .summary-details {
        margin-right: 0;
      }
    }
  }
}
