@import "~bootstrap/scss/mixins";
@import "../../scss/tsamixins";
@import "../../scss/tsavariables";
$icon-size: 1.125rem;
$text-margin: 0.3rem;
$activity-list-line-height: 1.125rem;
$activity-list-vert-padding: 0.25rem;
$activity-list-horz-padding: 0.6rem;
$activity-list-base-font-size: 0.813rem;
$activity-list-date-font-size: 0.625rem;
$activity-list-group-icon-width: 1.75rem;
.activity-list {
  position: relative;
  background-color: $white-smoke;
  font-size: $activity-list-base-font-size;
  line-height: $activity-list-line-height;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .activity-list-header {
    .activity-list-header-label {
      @include font-family-bold();
      margin-left: 0.75rem;
      line-height: 1.125rem;
      font-size: 0.813rem;
      color: $dark-gray-border;
      text-transform: uppercase;
      .activity-list-header-label-number {
        display: inline;
        padding-right: 0.5rem;
      }
    }
    .activity-carry-forward-all {
      margin-left: auto;
    }
  }
  .activity-list-help {
    @include font-family-bold();
    position: absolute;
    bottom: 0.5rem;
    padding: 0 2rem;
    font-size: 0.9rem;
    color: $gray-header;
    .downArrow {
      float: left;
      font-size: 3rem;
      stroke-width: 0;
      fill: $gray-header;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.2rem;
      .downArrow {
        font-size: 4rem;
      }
    }
  }
}

.activity-list-items {
  overflow: auto;
  overflow-x: hidden;
  margin-left: 0.5rem;
}

.activity-list-item {
  flex-grow: 1;
  flex-shrink: 0;
}

.activity-carry-forward-all {
  flex-grow: 0;
  flex-shrink: 0;
}

.activity-list-group {
  overflow: auto;
}

.activity-carry-forward-all,
.activity-list-group {
  display: flex;
  font-size: $activity-list-base-font-size;
  line-height: $activity-list-line-height;
  margin: $activity-list-vert-padding * 4 0;
  transition: background-color 0.5s ease;
  &.selected {
    background-color: $gray-reply;
  }
  .icon {
    height: $icon-size;
    width: $icon-size;
    stroke: rgb(245, 246, 246);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.28px;
    fill: #afb2b4;
  }
  .inline-comment,
  .tsa-alert {
    padding: 0 $activity-list-horz-padding;
  }
  .simple-link {
    padding-left: $activity-list-horz-padding;
    line-height: 1.5rem;
    outline: 0;
  }
}

.activity-carry-forward-all {
  margin: 0 0 0.5rem 0;
}

.activity-carry-forward-all-content {
  display: flex;
  margin-left: auto;
  padding-right: 1.5rem;
  align-items: center;
  justify-content: center;
  user-select: none;
  .icon {
    fill: transparent;
    stroke: $blue-curious;
    stroke-width: 1rem;
    margin-right: 0.5rem;
    &.active {
      fill: $blue-curious;
    }
  }
}

.activity-carry-forward-action {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.activity-carry-forward-link {
  display: flex;
}

.activity-list-group-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: $activity-list-vert-padding;
  min-width: $activity-list-group-icon-width;
  width: $activity-list-group-icon-width;
}

.activity-list-group-content {
  flex: 1 1 auto;
}

.activity-list-item {
  color: $gray;
  padding: $activity-list-vert-padding $activity-list-horz-padding;
  letter-spacing: 0.04rem;
  @include font-family-light();
  &.child {
    margin-left: $activity-list-horz-padding * 2;
  }
  .author,
  .mention {
    margin-right: $text-margin;
    @include font-family-bold();
  }
  .created-date {
    display: block;
    margin-top: 0.4rem;
    font-size: $activity-list-date-font-size;
    text-transform: uppercase;
    line-height: 1;
  }
  .simple-link {
    padding: 0;
    font-size: 0.75rem;
  }
}
