@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
@import '~bootstrap/scss/mixins';

.question-edit-add-rows {
  position: relative;

  .question-edit-add-rows-button {
    .question-edit-add-rows-button-label {
      display: flex;
      font-size: 0.813rem;
      height: 1.375rem;
      letter-spacing: 0.041rem;
      align-items: center;
      user-select: none;
      cursor: pointer;
    }

    .icon {
      margin-right: 0.5rem;

      fill: $white;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

.question-edit-add-rows-items {
  position: absolute;
  z-index: 3;
  width: 9rem;
  overflow: hidden;
  font-size: 0.813rem;
  letter-spacing: 0.056rem;
  border-radius: 0 0 0.25rem 0.25rem;
  left: 0;

  .selected {
    @include font-family-bold();
  }

  .tcont {
    color: $white;
    background-color: $blue-medium;
    padding: 0 0.6875rem 1.4rem 0.6875rem;

    & > div {
      padding-top: 1rem;
      cursor: pointer;
      user-select: none;
    }
  }
  &.open {
    border: 0.063rem solid $blue-border;
  }
}
