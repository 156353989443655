@import './tsavariables';
.tooltip {
  background-color: $tool-tip-color !important;
  opacity: 1 !important;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0.5rem;
  max-width: 12rem;
  z-index: $tool-tip-z-index;
  &:first-letter {
    text-transform: capitalize;
  }
  &.place-top:after {
    border-top-color: $tool-tip-color !important;
  }
  &.place-bottom:after {
    border-bottom-color: $tool-tip-color !important;
  }
  &.place-left:after {
    border-left-color: $tool-tip-color !important;
  }
  &.place-right:after {
    border-right-color: $tool-tip-color !important;
  }
}
