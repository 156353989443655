$background-color: rgb(97, 97, 97);
$active-background-color: lighten($background-color, 15%);
$border-color: darken($background-color, 10%);

.p-tieredmenu.p-component.p-tieredmenu-overlay {
  border-color: $border-color;
  background-color: $background-color;

  .p-menu-list {
    background-color: $background-color;

    overflow-y: auto;
  }
  .p-menuitem {
    background-color: $background-color;

    &.p-menuitem-active {
      background-color: $active-background-color;
    }
  }

  .p-submenu-list {
    border-color: $border-color;
    background-color: $background-color;
  }
}
