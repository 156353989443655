@import '../../scss/tsamixins';
@import '../../scss/tsavariables';
@import '~bootstrap/scss/mixins';

$margin-right: 1rem;
$margin-right-big: 2rem;

.comment-library-no-comments {
  @include font-family-bold();
  margin: 2rem;
  margin-top: 2.5rem;
  font-size: 1.5rem;
}

.comment-library {
  @include media-breakpoint-down(lg) {
    margin-left: $margin-right;
  }

  @include media-breakpoint-up(xl) {
    margin-left: $margin-right-big;
  }

  margin-top: 1.9375rem;
  color: $gray-dark;
  letter-spacing: 0.05rem;
  flex-grow: 1;
  overflow-x: hidden;
  @include font-family-light();

  .select-container {
    height: 1.25rem;
  }

  .summary-wrapper.has-checkboxes {
    margin-left: 1.5rem;
    margin-top: -1.25rem;
  }

  .summary-main-select .checkbox label::after {
    top: 0.3rem;
  }

  .form-check-label {
    cursor: pointer;
  }

  .comment-library-select-all {
    flex: auto;
  }

  button.send-email {
    width: 7rem;
    height: 2.375rem;
    margin-right: 2rem;
    border-radius: 0.5rem;
  }

  .summary-label {
    @include media-breakpoint-down(lg) {
      margin-right: $margin-right;
    }

    @include media-breakpoint-up(xl) {
      margin-right: $margin-right-big;
    }
  }
  .summary .summary-details {
    @include media-breakpoint-down(lg) {
      margin-right: $margin-right;
    }

    @include media-breakpoint-up(xl) {
      margin-right: $margin-right-big;
    }
  }

  svg.notification {
    fill: &gray;
    height: 1.8rem;
    margin-top: -0.125rem;
    margin-right: 0.2rem;
    width: 1.8rem;
    &.active {
      fill: $blue;
      cursor: pointer;
    }
    cursor: default;
  }

  div.icon.notificationError,
  div.icon.notificationPending,
  div.icon.notificationSent {
    height: 2rem;
    width: 2rem;
    margin-right: 0;

    svg:not(.badge) {
      height: 1.8rem;
      width: 1.8rem;
      fill: $blue;
    }

    svg.badge {
      fill: $blue;
      height: 85%;
      right: -8%;
      stroke: $white;
      top: -23%;
      width: 45%;

      polyline {
        stroke-width: 0.25rem;
      }
    }
  }
  div.icon.notificationError {
    svg.badge {
      fill: $red;
      &.exclamationColor {
        fill: $white;
        height: 46%;
        width: 46%;
        top: -3%;
        right: -8%;
      }
    }
  }
}
