@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
@import '~bootstrap/scss/mixins';

$table-color: $blue;
$calendar-days-font-color: $white;
$table-border-width: 0.071428571em;
$table-border-style: solid;
$table-margin-bottom: 1.529411765em;
$table-cell-padding: 0.470588235em;
$table-border-color: $black;
$table-selected-color: $white;
$table-header-text-align: center;
$table-cell-text-align: center;
$table-selected-background: $gray;
$table-font-style: uppercase;
$table-cell-border-top: $table-border-width $table-border-style
  $table-border-color;
$table-body-border-top: 0.142857143em $table-border-style $table-border-color;
$table-condensed-padding: 0.294117647em;
$table-bordered-border: $table-border-width $table-border-style
  $table-border-color;
$table-border-left: $table-border-width $table-border-style $table-border-color;
$shadow-arrow-day-font: 0 0.063rem 0 rgba(15, 98, 50, 0.25);
$textbox-border-style: solid;

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

/* Styles Copied from the library (removed few styles which are not used) */

.rdt {
  position: relative;
  width: 100%;
}

.rdtPicker {
  display: none;
  position: absolute;
  z-index: $date-picker-z-index;
}

.rdtOpen .rdtPicker {
  display: block;
  width: 20rem;

  @include media-breakpoint-down(sm) {
    width: 15rem;
    font-size: 0.8rem;
    // If moving to the left prematurely, it looks bad, using custom media breakpoint to only move it for the smallest screens where it needs to be moved
    @media (max-width: 21.375rem) {
      left: -1.5rem;
    }
  }
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: $table-color;
  cursor: pointer;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: $table-cell-color;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid $table-color;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 1px;
  right: 1px;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: $table-cell-color;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: $table-cell-color;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: $table-cell-color;
  cursor: not-allowed;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}

.rdtPicker th.rdtSwitch {
  width: 100px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 1.3rem;
  vertical-align: middle;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: $table-cell-color;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: $table-hover-color;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: $table-hover-color;
}

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

/* End of Styles Copied from the library */

.rdtPicker .rdtDays,
.rdtTime,
.rdtMonths,
.rdtYears {
  .table th,
  %table th,
  .table td,
  %table td {
    padding: $table-cell-padding;
    text-align: $table-cell-text-align;
    border-top: $table-cell-border-top;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    background-color: $table-background-color;
    thead {
      height: 2.438rem;
      background-color: $table-color;
      border: 1px $table-border-style $table-border-color;
      text-align: $table-header-text-align;
      color: $white;
      tr:first-child {
        height: 2.438rem;
      }
      .rdtSwitch {
        font-size: $font-size-base;
        border-left: 1px $table-border-style $table-border-color;
        border-right: 1px $table-border-style $table-border-color;
      }
    }
    thead .rdtPrev {
      height: 0.875rem;
      box-shadow: $shadow-arrow-day-font;
    }
    thead tr {
      border: 1px $table-border-style $table-border-color;
      text-transform: $table-font-style;
      @include font-family-bold();
    }
    thead tr:nth-last-child(1) {
      height: 2.063rem;
      width: 1.375rem;
      text-shadow: $shadow-arrow-day-font;
      color: $calendar-days-font-color;
      font-size: 0.688rem;
      line-height: 2.25rem;
      text-transform: $table-font-style;
      letter-spacing: 0.034rem;
      text-align: center;
      @include font-family-light();
    }
    tbody,
    tfoot {
      tr,
      tr td,
      tr td .rdtday {
        border: 1px $table-border-style $table-border-color;
      }
      tr {
        width: 18.813rem;
        height: 2.7rem;
      }
      tr td {
        padding: 0.5625rem;
        text-align: $table-cell-text-align;
      }
      tr td {
        .rdtDay {
          padding: $table-cell-padding;
          text-align: $table-cell-text-align;
        }
      }
      tr td:hover {
        background-color: $table-color;
        cursor: pointer;
      }
      tr td.rdtActive {
        z-index: $zero-z-index;
        background-color: $table-selected-background;
        color: $table-selected-color;
      }
    }
  }
}

.calendarIcon {
  color: $table-color;
  width: 1.7rem;
  height: 1.7rem;
  float: right;
  right: 1%;
  top: 1.3rem;
  z-index: 500;
  position: relative;
  cursor: pointer;
}

.form-group.disabled .calendarIcon {
  cursor: default;
}

.selected {
  .is-valid {
    .form-control {
      border: 1px $textbox-border-style $green;
    }
    .invalidDate {
      background: none;
      color: $green;
    }
    .calendarIcon {
      background: none;
      color: $green;
    }
  }
  .is-invalid {
    .form-control {
      border: 1px $textbox-border-style $red;
    }
    .invalidDate {
      background: none;
      color: $red;
    }
    .calendarIcon {
      background: none;
      color: $red;
    }
  }
}

.is-hovered,
.is-selected {
  .form-control {
    border-color: $blue;
  }
}
