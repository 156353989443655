@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
@import '~bootstrap/scss/mixins';
.engagement-summary {
  margin: 0.75rem 2rem;
  padding: 2rem 1.5rem;
  background-color: $white;
  color: $gray;
  .engagement-name {
    font-size: 2rem;
    line-height: 1;
  }
  a {
    color: $blue-menu;
    &:hover {
      color: $blue-menu;
      text-decoration: none;
    }
  }
  .summary-label {
    font-weight: 700;
  }
  .duedate {
    margin-right: 1rem;
  }
  .date,
  .duedate {
    text-transform: uppercase;
  }
  @include media-breakpoint-up(md) {
    .cchtype,
    .cchversion,
    .clientid,
    .lastQuestion,
    .taxyear {
      margin-left: 1rem;
    }
  }
  .heart {
    font-size: 2rem;
    fill: none;
    stroke-width: 0.8em;
    stroke: $blue-menu;
    &:hover,
    &.favorited {
      fill: $blue-menu;
    }
    &.favorited:hover {
      filter: drop-shadow(0 0 0.05em $gray);
    }
  }
  .edit {
    font-size: 2rem;
    fill: none;
    stroke-width: 0.8em;
    stroke: $blue-menu;
     &:hover {
       fill: $blue-menu;
     }
    
  }
  svg.locked {
    font-size: 2rem;
    fill: $blue-menu;
    stroke-width: 0.8em;
    stroke: $blue-menu;
    &:hover {
      fill: $blue-menu;
      filter: drop-shadow(0 0 0.05em $gray);
    }
    &.disabled {
      fill: $gray;
      stroke: $gray;
      filter: none;
    }
  }
  svg.unlocked {
    font-size: 2rem;
    fill: none;
    stroke-width: 0.8em;
    stroke: $blue-menu;
    &:hover {
      fill: $blue-menu;
    }
  }
}

.engagement-summary-info {
  line-height: 1.5rem;
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  div {
    display: inline;
  }
}

.engagement-summary-info1 {
  color: $blue-menu;
  font-size: 0.7rem;
  line-height: 1.5rem;
  display: flex;
  .engagement-summary-buttons {
    margin-left: auto;
  }
}

.engagement-summary-buttons {
  display: flex;
  align-items: center;
  * {
    margin-left: 0.5rem;
    &:first-child {
      margin-left: 0;
    }
  }
  @include media-breakpoint-up(md) {
    margin-top: 1.25rem;
    * {
      margin-left: 1rem;
    }
  }
  button {
    height: 3.25rem;
    margin-top: 1.25rem;
    border-radius: 0.25rem;
    text-transform: uppercase;
    background-color: $blue-menu;
    min-width: 8.5rem;
    white-space: normal;
    padding: 0.25rem 0.5rem;
    @include media-breakpoint-up(md) {
      margin-top: 0;
      padding: 0.375rem 0.75rem;
    }
  }
  span {
    margin-top: 1.25rem;
    color: $gray-oslo;
    text-transform: uppercase;
    font-size: 0.875rem;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
}

.engagement-summary-message {
  margin: 1rem 0;
  font-size: 0.875rem;
  line-height: 1rem;
}

.submission-failed {
  .submission-failed-heading {
    color: $red;
  }
  .submission-failed-message {
    margin-top: 1rem;
  }
}

.submission-success {
  .submission-success-heading {
    display: inline;
    color: $green;
  }
  .submission-success-message {
    margin-top: 1rem;
  }
}

// Loading ellipsis
.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.error-links {
  cursor: pointer;
}
