@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
@import '~bootstrap/scss/mixins';

$transition-opacity: opacity 200ms ease-in-out;
$left-right-padding: 0.125rem;

.summary-icons {
  white-space: nowrap;
  display: flex;
  padding-right: 0.5rem;

  .icon-container {
    padding-left: $left-right-padding;
    padding-right: $left-right-padding;
  }

  .summary-icons-icon {
    opacity: 0;
    transition: $transition-opacity;
    stroke: $white-smoke;
    fill: $dark-gray-border;
    height: 0.5rem;
    width: 0.5rem;

    &.always-show {
      opacity: 1;
      fill: $gray-always-show;
    }
    &.show {
      opacity: 1;
    }
    &.requires-attention {
      fill: $red;
    }
  }

  .summary-icons-icon.error {
    stroke: $red;
    fill: $red;
  }
}
