@import "../../scss/tsavariables";
button.simple-link {
  color: $blue-menu;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;

  &:hover {
    color: $blue-menu;
    text-decoration: none;
  }
  &.disabled {
    color: $blue-disabled;
    cursor: default;
  }
}
button.simple-link.no-text-transform {
  text-transform: none;
}