@import "../../scss/tsavariables";
@import "../../scss/tsamixins";

.file-upload-dialog {
  .fileAlt {
    font-size: 5em;
    stroke: $white;
    fill: gray;
  }

  .icon.deleteAlt {
    fill: #039cde;
    margin-right: 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    max-width: 40rem;
  }

  .file-dialog-header {
    display: flex;
    flex-direction: row;
    max-width: 100%;

    .file-dialog-info {
      font-size: 0.75rem;
    }
  }

  .file-dialog-drop {
    width: 100%;
    user-select: none;

    .dropzone {
      border-radius: 2rem;
      color: $white;
      line-height: 2rem;

      .dropzone-header-info {
        font-size: 0.8rem;
      }

      .dropzone-header-instructional {
        @include font-family-italics();
        font-size: 0.8rem;
      }
    }
  }

  .file-dialog-duplicate-warning {
    font-size: 1.75em;
    margin: 1rem 3rem 0.5rem 3rem;
    line-height: 2rem;
    text-align: center;
  }

  .file-dialog-duplicate-warning-info {
    font-size: 1.1em;
    line-height: 1.5rem;
    text-align: center;
  }

  .file-dialog-files {
    width: 100%;

    .file-dialog-files-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-right: 2rem;
      margin-top: 1rem;
      margin-left: 1.5rem;
      margin-bottom: 0.5rem;
      max-height: 10.5rem;
      overflow-y: auto;

      .file-upload-file {
        width: 15rem;
        user-select: none;
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;

        .file-upload-file-duplicate {
          @include font-family-bold();
          margin-right: 0.2rem;
        }

        .file-upload-file-name {
          font-size: 0.8em;
        }
      }
    }

    .tsa-alert {
      margin-left: 2rem;
    }
  }

  .file-dialog-action {
    margin-top: 2rem;

    .btn {
      color: $white;
      background-color: #039cde;
      border-radius: 1rem;
      text-transform: uppercase;
      padding: 1rem 2rem 1rem 2rem;

      .disabled {
        background-color: lighten(#039cde, 25%);
        cursor: default;
      }
    }
  }
}
