@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
@import '~bootstrap/scss/mixins';

.navbar {
  display: flex;
  height: $navbar-height;
  border-bottom: 2px solid $green-forest;
}

.navbar-logo {
  //fill: $white;
  background-color: #159bd7 !important;
  width: $navbar-width;
  text-align: center;
  &:hover,
  &.active {
    background-color: $blue;
  }
  svg {
    fill: $white;
    height: 100%;
    width: 100%;
    padding: 0.25rem;
  }
}

@include media-breakpoint-up(lg) {
  .navbar {
    height: $navbar-height-lg;
  }
  @media screen and (min-height: $left-nav-min-height) {
    .navbar-logo {
      width: $navbar-width-lg;
      svg {
        padding: 0.5rem;
      }
    }
  }
}

@media screen and (min-height: $left-nav-min-height) {
  @include media-breakpoint-up(xl) {
    .navbar-logo {
      width: $navbar-width-xl;
    }
  }
}

.navbar-content,
.navbar-content-left,
.navbar-content-right {
  display: flex;
  align-items: center;
}

.navbar-content {
  justify-content: space-between;
  padding: 0 1rem;
  flex-grow: 1;
  @include media-breakpoint-up(xl) {
    padding: 0 2rem;
  }
}

.navbar-content-right {
  font-size: 0.813rem;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  > div:not(:last-child) {
    margin-right: 1rem;
  }
  .dropdown-toggle::after {
    vertical-align: middle;
  }
}

.navbar-due-date {
  color: $gray-dim;
  .navbar-due-date-divider {
    padding: 0 0.563rem;
    color: $gray-ash;
    &.warning {
      color: $orange;
    }
    &.error {
      color: $red;
    }
  }
  &.warning {
    color: $orange;
  }
  &.error {
    color: $red;
  }
}

// Engagement Dropdown Menu
.show {
  display: block;
}

div {
  &:focus {
    outline: none;
  }
}

%dropdown-container {
  position: relative;
  @include font-family-base();
  color: $blue-menu;
  user-select: none;
  max-width: 25rem;
  .dropdown-select-text {
    @include text-one-line-ellipsis-truncation;
  }
}

.engagement-dropdown-container {
  @extend %dropdown-container;
  .dropdown-select-text {
    max-width: 12rem;
    display: inline-block;
    vertical-align: middle;
  }
}

.due-date-dropdown-container {
  @extend %dropdown-container;
}

.warning::after {
  color: $orange;
}

.error::after {
  color: $red;
}

%dropdown-menu-list {
  position: absolute;
  top: 2.7rem;
  right: 0;
  background-color: $blue-menu;
  z-index: $top-nav-profile-menu-tip-z-index;
  @include font-family-base();
  // font-size: $font-size-base;
  font-weight: 500;
  color: $white;
  text-align: left;
  letter-spacing: 0.05rem;
  line-height: 1.125rem;
  max-width: 37.5rem;
  a,
  .due-date-list-item {
    display: block;
    width: 100%;
    text-decoration: none;
    font-size: 0.875rem;
    line-height: 1.125rem;
    @include text-one-line-ellipsis-truncation;
  }
  :before {
    content: '';
    position: absolute;
    right: 1rem;
    top: -0.7rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1rem 0.7rem 1rem;
    border-color: transparent transparent $blue-menu transparent;
  }
}

.engagement-dropdown-menu-list {
  @extend %dropdown-menu-list;
  color: $white !important;
  :hover,
  :focus {
    background-color: $blue-menu;
    color: $gray-black !important;
    text-decoration: none;
  }
  .active {
    color: $gray-black !important;
    pointer-events: none;
    cursor: default;
  }
  a {
    color: $white;
    padding: 1rem 1.5rem;
  }
}

.due-date-dropdown-menu-list {
  @extend %dropdown-menu-list;
  top: 2.6rem;
  > div {
    padding: 0.625rem 0;
  }
  .due-date-list-item.due-date-header {
    font-size: 1rem;
    color: $white;
  }
  .due-date-list-item {
    padding: 0.75rem 1.5rem;
    .due-date-icon,
    .due-date-status {
      display: inline-block;
    }
    .due-date-icon {
      height: 2.25rem;
      width: 2rem;
      vertical-align: top;
    }
    svg.icon {
      fill: $blue-selected-cell;
      height: 1.25rem;
      width: 1.25rem;
      position: relative;
      top: 0.1rem;
    }
    .due-date {
      color: $blue-selected-cell;
    }
  }
}

// Progress Bar
.navbar-progress {
  display: none;
  color: $gray-dim;
  width: 17rem;
  text-align: center;
  @include font-family-bold();
  font-size: 0.563rem;
  line-height: 1rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  .progress {
    height: 0.75rem;
  }
  .bg-success {
    background-image: linear-gradient(
      270deg,
      $green-light 0%,
      $green-forest 100%
    );
  }
}

@include media-breakpoint-up(lg) {
  .navbar-progress {
    display: block;
  }
}
