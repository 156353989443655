@import '../../scss/tsavariables';
@import '../../scss/tsamixins';

.radio-buttons {
  input[type='radio']:checked + label {
    .center {
      fill: $gray-medium;
    }
  }
  input[type='radio']:focus + label {
    outline: 0.05rem solid $blue;
    outline-offset: 0.188rem;
  }
  label {
    cursor: pointer;
  }

  &.disabled label {
    cursor: default;
  }
}

.radio-button-circle {
  margin-right: 0.5rem;
  width: 1.375rem;
  height: 1.375rem;
  .border {
    stroke: $gray-dark;
    stroke-width: 1px;
    fill: $white;
  }
  .center {
    fill: $body-bg;
    stroke-width: 0;
  }
}

.selected {
  .is-valid {
    .radio-button-circle {
      .border {
        stroke: $green;
      }
    }
  }

  .is-invalid {
    .radio-button-circle {
      .border {
        stroke: $red;
        stroke-width: 2px;
      }
    }
  }
}
