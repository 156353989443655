@import '~bootstrap/scss/mixins';
@import '../../scss/tsamixins';
@import '../../scss/tsavariables';

.iframe-decorator-story {
  .savingIcon {
    .hint-text {
      color: black !important;
    }

    svg.icon.saving {
      fill: black !important;
      stroke: black !important;
    }
  }
}

.savingIcon {
  display: flex;
  flex-direction: row;

  svg.icon.saving {
    fill: $white;
    height: 1.5625rem;
    stroke: $white;
    stroke-width: 0.06rem;
    width: 1.5625rem;
  }

  .hint-text {
    color: $white;
    font-size: 0.75rem;
    @include font-family-italics();

    @include media-breakpoint-down(xs) {
      br {
        display: none;
      }
    }

    &.icon-hidden {
      margin-right: 2.25rem;
    }
  }

  .vertically-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right-padding-small {
    padding-right: 0.5rem;
  }

  @include media-breakpoint-down(xs) {
    height: 1.25rem;
  }
}
