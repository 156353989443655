@import '../../scss/tsamixins';
@import '../../scss/tsavariables';
@import '~bootstrap/scss/mixins';
$section-links-icon-size: 1.125rem;
$section-links-number-font-size: 0.75rem;
.section-links {
  color: $white;
  padding-bottom: 3rem;
  background-color: $blue;
  overflow-y: auto;
  &::-webkit-scrollbar-thumb {
    background: $blue;
    &:hover {
      background: $blue-dark;
    }
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  h1 {
    padding: 1rem 0 1rem 1rem;
    margin: 0;
    letter-spacing: 0.063rem;
    @include font-family-base();
  }
}

a.section-link {
  display: flex;
  position: relative;
  padding: 0.5rem 0.5rem 0.5rem 2.25rem;
  color: $white;
  line-height: $section-links-icon-size;
  letter-spacing: 0.04rem;
  &:hover {
    background-color: lighten($blue, 5%);
    text-decoration: none;
  }
  &.complete {
    .section-number,
    .section-title {
      opacity: 0.5;
    }
  }
  &.active {
    cursor: default;
    pointer-events: none;
    &:hover {
      background-color: inherit;
    }
    .section-number,
    .section-title {
      opacity: 1;
      @include font-family-bold();
    }
  }
  .section-number {
    min-width: 2rem;
    text-align: right;
    padding-right: 0.4rem;
    font-size: $section-links-number-font-size;
  }
  svg.icon {
    position: absolute;
    left: 0.75rem;
    top: 0.5rem;
    fill: $white;
    stroke: $blue;
    height: $section-links-icon-size;
    width: $section-links-icon-size;
    opacity: 0.8;
    &:not(.error) {
      stroke-width: 0.15em;
    }
    &.error {
      stroke-width: 0.01em;
    }
  }
  .icon.na {
    position: absolute;
    left: 0.75rem;
    top: 0.5rem;
    height: $section-links-icon-size;
    width: $section-links-icon-size;
    line-height: $section-links-icon-size;
    background-color: $white;
    color: $blue;
    opacity: 0.8;
    .text {
      font-size: 0.6rem;
    }
  }
}

.tasks-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .section-links {
    flex-grow: 1;
    flex-shrink: 1;
  }
  label {
    color: $white;
    background-color: $blue-midnight;
    flex-shrink: 0;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    font-size: 0.813rem;
    line-height: 24px; // using px to match code
    letter-spacing: 0.063rem;
    margin-bottom: 0;
    &:last-child {
      padding-bottom: 0.75rem;
    }
  }
  label > span {
    flex: 1 1 0%;
  }
  label > span > span {
    font-size: 0.5rem;
  }
}
