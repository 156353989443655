@import '../../scss/tsamixins';
@import '../../scss/tsavariables';
@import '~bootstrap/scss/mixins';

$margin-right: 1rem;
$margin-right-big: 2rem;

.flag-library-no-flags {
  @include font-family-bold();
  margin: 2rem;
  margin-top: 2.5rem;
  font-size: 1.5rem;
}

.flag-library {
  @include media-breakpoint-down(lg) {
    margin-left: $margin-right;
  }

  @include media-breakpoint-up(xl) {
    margin-left: $margin-right-big;
  }

  margin-top: 1.9375rem;
  color: $gray-dark;
  letter-spacing: 0.05rem;
  flex-grow: 1;
  overflow-x: none;
  @include font-family-light();

  .summary-label {
    @include media-breakpoint-down(lg) {
      margin-right: $margin-right;
    }

    @include media-breakpoint-up(xl) {
      margin-right: $margin-right-big;
    }
  }
  .summary .summary-details {
    @include media-breakpoint-down(lg) {
      margin-right: $margin-right;
    }

    @include media-breakpoint-up(xl) {
      margin-right: $margin-right-big;
    }
  }
}
