@import "../../scss/tsavariables";
@import "../../scss/tsamixins";
@import "~bootstrap/scss/mixins";
$question-icon-size: 1.8rem;
$question-icon-padding: 0.75rem;
$question-circle-size: 3.3rem;
$transition-background: background-color 200ms ease-in-out;
$transition-opacity: opacity 200ms ease-in-out;
.question-edit {
  position: absolute;
  background-color: $white-smoke;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  .question-edit-form {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    & > div:nth-child(2) {
      flex-grow: 1;
    }
    .question-edit-footer-metadata {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 70%;
      }

      @include media-breakpoint-up(lg) {
        width: 80%;
      }

      @include media-breakpoint-up(xl) {
        width: 85%;
      }
    }
    .question-edit-footer,
    .question-edit-label {
      flex-grow: 0;
      flex-shrink: 0;
    }
    .question-edit-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.85rem;
      margin-top: 0.85rem;
      margin-left: 2.5rem;
      margin-right: 1.5rem;
    }
    .question-edit-label {
      padding-bottom: 0.5rem;
      padding-left: 2.8125rem;
      letter-spacing: 0.038rem;
      background-color: $blue-dark;
      color: $white;
      .question-edit-label-info {
        background-color: $green-caribbean;
        margin-left: -2.8125rem;
        font-size: 0.8rem;
        padding: 0.5rem 0.5rem 0.25rem 0.5rem;
        .label {
          @include font-family-bold();
        }
      }
      .question-edit-label-text {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .text {
          font-size: 1.5rem;
          line-height: 1.75rem;
          max-width: 50rem;
          margin-right: auto;
        }
        .buttons {
          // margin-left: 9.125rem;
          display: flex;
          align-items: center;
          flex-shrink: 1;
          flex-grow: 0;
          .buttons-center {
            display: flex;
            flex-wrap: wrap-reverse;
            .done,
            .cancel:hover {
              cursor: pointer;
            }
            .done,
            .cancel {
              width: 10.688rem;
              height: 3.125rem;
              border-radius: 0.25rem;
              border: 0.063rem solid;
              font-size: 0.875rem;
              letter-spacing: 0.061rem;
              text-align: center;
              vertical-align: middle;
              line-height: 3.125rem;
              text-transform: uppercase;
              user-select: none;
              flex-shrink: 0;
              flex-grow: 0;
              margin-top: 1rem;
              margin-right: 1rem;
              cursor: pointer;
            }
            .cancel {
              border-color: $blue-border;
              background-color: $blue-triangle-open;
              margin-right: 0.75rem;
            }
            .done {
              background-color: $white;
              color: $blue-triangle-open;
              border: $blue-border;
              margin-right: 1.625rem;
            }
          }
        }
      }
    }
    .question-hint-text {
      color: $white;
    }
    .data-grid-container .data-grid {
      margin-top: 0;
    }
    .question-modify-rows {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 1rem;
      margin-left: 0.5rem;
      .add-delete-container {
        display: flex;
      }
      .delete-rows,
      .copy-all,
      .add-rows {
        display: flex;
        font-size: 0.813rem;
        letter-spacing: 0.041rem;
        align-items: center;
        user-select: none;
        cursor: pointer;
      }
      .add-rows {
        margin-left: 2rem;
      }
      .delete-rows,
      .copy-all {
        min-width: 5rem;
      }
      .copy-all-text {
        font-style: italic;
        z-index: 1;
      }
      .copy-all-text-dismiss {
        margin-left: 0.5rem;
        text-decoration: underline;
        z-index: 2;
      }
      .question-edit-add-rows {
        width: 5rem;
      }
      .icon {
        margin-right: 0.5rem;
        &.delete,
        &.copy,
        &.plusCircle {
          fill: $white;
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }
  .question-edit-buttons {
    align-self: flex-end;
    display: none;
    justify-content: flex-end;
    padding: 0.5rem 0.5rem 0rem 0rem;
    transition: $transition-opacity;
    @include media-breakpoint-down(sm) {
      display: none !important;
    }
  }
  @media (hover: hover) {
    &:hover:not(.disabled) .question-edit-buttons {
      display: flex;
    }
  } // ie only ie does not support the hover media query so used this instead
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    &:hover:not(.disabled) .question-edit-buttons {
      display: flex;
    }
  }
  &.selected .question-edit-buttons {
    display: flex;
  }
  .question-edit-icon-large.clickable {
    padding-bottom: 0.3rem;
  }
  .question-edit-icon-large.circleBigCheck {
    display: none;
    margin-left: 0rem;
    margin-right: 1rem;
    height: 3.7rem;
    width: 3.7rem;
    stroke: rgba($green, 0.8);
    fill: transparent;
    cursor: pointer;
    &.active {
      fill: rgba($green, 0.8);
      &:hover {
        fill: $green;
      }
    }
    &:hover {
      stroke: $green;
    }
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
  div.question-edit-icon-large {
    background-color: $blue;
    border-radius: $question-circle-size / 2;
    display: none;
    height: $question-circle-size;
    padding: $question-icon-padding;
    width: $question-circle-size;
    align-self: center;
    &:not(.active) {
      background-color: transparent !important;
      border: 0.1rem solid $blue !important;
      .icon {
        fill: $blue;
        stroke: $blue;
      }
    }
    @include media-breakpoint-up(md) {
      display: block;
    }
    cursor: pointer;
    .icon {
      height: $question-icon-size;
      width: $question-icon-size;
      stroke: $white;
      fill: $white;
    }
  }
  @include media-breakpoint-up(md) {
    div.question-edit-icon-large {
      display: block;
    }
  }
}
