@import '../../scss/tsavariables';
@import '../../scss/tsamixins';

.dropdown-component {
  font-size: 0.813rem;
  .dropdown-label {
    margin-bottom: 0.5rem;
  }
  .btn {
    display: flex;
    align-items: stretch;
    padding: 0;
    width: 100%;
  }
  .btn.placeholder .btn-label {
    opacity: 0.4;
  }
  .btn-label {
    padding: 0.375rem 0.75rem;
    flex: 1 1 auto;
  }
  .btn-toggle {
    border-left: 1px solid $gray-border;
    padding: 1rem 0.75rem;
    .triangle {
      @include triangle-dimensions(bottom, $white, 0.313rem, 0.688rem);
    }
  }
  .dropdown-item {
    user-select: none;
    &.disabled {
      color: $gray-icon;
    }
    color: $white;
    background-color: $blue-medium;
    padding: 0 0.6875rem 1.4rem 0.6875rem;
    font-size: 0.813rem;
  }
  .dropdown-menu {
    border-radius: 0 0 0.25rem 0.25rem;
  }
}

// TODO: Move custom styles to their parent component folder.

.dropdown.sub-header-dropdown {
  min-width: 10rem;
  .btn,
  .btn:hover {
    background-color: $blue-dark;
  }
  .btn {
    color: $white;
    border: 1px solid $blue-border;
    border-radius: 4px;
    text-transform: uppercase;
    text-align: left;
  }
  .btn-toggle {
    border-left-color: $blue-border;
  }
  &.show .btn,
  .dropdown-menu,
  a.dropdown-item {
    color: $white;
    background-color: $blue-medium;
  }
  a.dropdown-item.active {
    @include font-family-bold();
  }
}

.sub-header-dropdown-date-container {
  &.show-date {
    & > .sub-header-dropdown {
      margin-right: 1.9rem;
    }
  }

  .date-section {
    display: flex;
    font-size: 1rem;
    .sub-header-dropdown-pick-date {
      @include font-family-bold();
      margin-left: 0.5rem;
      text-transform: uppercase;
      cursor: pointer;
      user-select: none;
      margin-top: 0.7rem;
    }
    .calendarIcon {
      height: 1.3rem;
      width: 1.3rem;
      top: 0.8rem;
      right: 3%;
      fill: $white;
      stroke: $white;
    }
    .form-group input {
      @include font-family-italics();
      margin-top: 0;
      height: 1.8rem;
      background-color: $blue-dark;
      color: $white;
      border-color: $blue-border;
      border-radius: 0.3rem;
      padding: 0;
      padding-left: 0.75rem;

      &::placeholder {
        @include font-family-italics();
        color: white;
      }
    }
    .rdtYear,
    .rdtMonth,
    .rdtDay:not(.rdtOld):not(.rdtNew) {
      color: $gray-dark;
    }
  }
}
