@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
@import '~bootstrap/scss/mixins';

.draft-test {
  // @include text-one-line-ellipsis-truncation;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.file-upload-advanced-header {
  padding: 0 2rem;
  height: 5.25rem;
  align-items: center;
  font-size: 1.75rem;
  color: $white;
  background-color: $blue-dark;
  line-height: 1.25;
  display: flex;
  flex-direction: row;
  .file-upload-advanced-header-title {
    color: $white;
    line-height: 1.625rem;
    font-family: $font-family-base;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
  }
  .file-upload-advanced-header-status {
    font-size: 0.9rem;
    padding-left: 1rem;
    margin-top: -18px;
    > p {
      margin: 0;
    }
  }
  .file-upload-advanced-header-buttons {
    margin-left: auto;
    .file-upload-advanced-buttons-add-documents {
      width: 10rem;
      height: 2.5rem;
      border-radius: 0.25rem;
      border: 0.063rem solid $blue-border;
      background-color: $white;
      color: $blue-menu;
      font-family: $font-family-base;
      font-size: 0.813rem;
      font-weight: 400;
      text-transform: uppercase;
    }
    .icon {
      fill: $blue-menu;
    }
  }
}

.file-upload-advanced {
  height: 100%;
  width: 100%;
  button {
    text-transform: uppercase;
    color: $blue-menu;
  }
  .icon {
    fill: $gray;
    width: 1rem;
    height: 1.125rem;
    margin-right: 0.25rem;
    &.active {
      fill: $blue-menu;
    }
  }
  .navbar-progress {
    margin-left: auto;
    text-align: right;
    padding: 1rem 1.875rem 0 1rem;
  }
  .file-upload-advanced-content {
    padding: 0 1.875rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    &.disabled {
      height: 90%;
      margin-bottom: 3em;
    }
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
    .file-upload-advanced-buttons--hidden {
      top: -1000px;
    }
    .file-upload-advanced-buttons {
      display: flex;
      position: absolute;
      bottom: 1rem;
      cursor: pointer;
      @include media-breakpoint-down(sm) {
        display: none;
      }
      .file-upload-advanced-buttons-download,
      .file-upload-advanced-buttons-delete,
      .file-upload-advanced-buttons-draft {
        margin-right: 1rem;
        width: 11rem;
        height: 3.25rem;
        border-radius: 0.25rem;
        background-color: $blue-menu;
        color: $white;
        cursor: pointer;
        font-family: $font-family-base;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.578rem;
        text-transform: uppercase;
        letter-spacing: 0.049rem;
        z-index: 10;
        &.disabled {
          display: none;
          cursor: default;
        }
        .icon {
          fill: $white;
        }
      }
      .file-upload-advanced-buttons-draft {
        .icon {
          height: 1.625rem;
          width: 1.5625rem;
        }
      }
    }
    .file-list-grid {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 0;
      @include media-breakpoint-up(md) {
        margin-top: 1.25rem;
      }
      .file-list-row-header {
        display: flex;
        flex-wrap: wrap;
        height: 2.5rem;
        align-items: center;
        @include media-breakpoint-up(md) {
          background-color: $blue-menu;
          color: $white;
        }
        font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial';
        font-weight: 700;
        font-style: normal;
        font-size: 0.75rem;

        & > div {
          margin-top: auto;
          margin-bottom: auto;
        }

        .file-list-column-name {
          display: none;
          @include media-breakpoint-up(xs) {
            display: inline;
          }
          [type='checkbox'] {
            margin-right: 0.5rem;
            margin-top: 0.2rem;
          }
        }
        .file-list-column-by {
          display: none;
          @include media-breakpoint-up(xs) {
            display: block !important;
          }
        }
        .file-list-column-status {
          display: none;
          @include media-breakpoint-up(xs) {
            display: inline;
          }
        }
        .file-list-column-buttons {
          margin-left: auto;
          @include media-breakpoint-up(xs) {
            text-align: right;
          }
        }
        .file-list-column-subject-to-privilege,
        .file-list-column-draft {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }

        .clickable {
          user-select: none;
          cursor: pointer;
        }
      }
      .file-list-rows {
        flex-grow: 1;
      }
      .file-list-footer {
        display: none;
        height: 1.25rem;
        @include media-breakpoint-up(lg) {
          display: block !important;
        }
      }
      .file-list-row {
        display: flex;
        flex-wrap: wrap;
        font-size: 0.6875rem;
        font-family: 'ArialMT', 'Arial';
        font-weight: 400;
        font-style: normal;
        padding-top: 0.5625rem;
        &.alt {
          background-color: $white-smoke;
        }
        a {
          color: $blue-dark;
        }
        .file-list-column-name {
          height: 3.5rem;
          @include media-breakpoint-up(lg) {
            height: 1.5rem;
          }
          [type='checkbox'] {
            margin-right: 0.5rem;
            vertical-align: text-bottom;
          }
        }
        .file-list-column-by {
          display: none;
          @include media-breakpoint-up(lg) {
            display: block !important;
            height: 2.5rem;
          }
          @include media-breakpoint-up(xl) {
            display: flex !important;
            height: 1.5rem;
          }
        }
        .file-list-column-subject-to-privilege,
        .file-list-column-draft {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
        .file-list-column-draft,
        .file-list-column-status {
          margin-top: 0.6875rem;
          @include media-breakpoint-up(md) {
            margin-top: 0;
          }
          .file-list-column-status-draft {
            padding-left: 0.5rem;
          }
        }
        .file-list-column-subject-to-7216-yes,
        .file-list-column-subject-to-privilege-yes {
          padding-left: 0.5rem;
        }
        .file-list-column-buttons {
          // cursor: pointer;
          margin-left: auto;
          margin-top: 0.6875rem;
          text-align: right;
          height: 1.5rem;
          @include media-breakpoint-up(md) {
            margin-top: 0;
            padding: 0 0.5625rem 0 0;
          }
          .active {
            cursor: pointer;
          }
          .icon .active {
            fill: $blue-menu;
            width: 1rem;
            height: 1.125rem;
            margin-right: 0.625rem;
          }
          .configure-icon {
            display: inline;
            .configure-icon-tooltip {
              position: relative;
              z-index: 10000;
              top: -1.8125rem;
              left: -1.25rem;
            }
            .configure-icon-popup {
              border-radius: 0.75rem !important;
              background-color: $blue-menu;
              width: 9.375rem;
              height: 5rem;
              text-align: left;
              margin-left: auto;
              div {
                padding: 0.625rem 0.3125rem 0 0.625rem;
                a {
                  color: $white;
                  text-decoration: none;
                }
              }
            }
          }
          svg.badge {
            height: 50%;
            position: absolute;
            right: 2.5%;
            top: -10%;
            fill: $red;
            &.exclamationColor {
              fill: $white;
              height: 46%;
              width: 46%;
              top: -3%;
              right: -8%;
            }
          }
        }
        .file-list-column-name,
        .file-list-column-by-name,
        .file-list-column-by-date,
        .file-list-column-status,
        .file-list-column-uploaded {
          @include text-one-line-ellipsis-truncation();
        }
      }
      .no-files-found {
        padding: 0 3rem;
        @include media-breakpoint-down(sm) {
          display: none !important;
        }
      }
    }
    .file-version-list {
      position: absolute;
      z-index: 10000;
      top: 20px;
      right: 12px;
      width: 28rem;
      color: $white;
      border-radius: 0.75rem !important;
      background-color: $blue-menu;
      text-align: left;
      margin-left: auto;
      padding: 15px;
      padding-top: 8px;
      .file-version-rows {
        max-height: 10rem;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .file-version-rows a {
        color: $white;
      }
      .icon {
        fill: $white;
        stroke: $white;
        stroke-width: 3rem;
        margin-bottom: 5px;
        position: relative;
        right: -7px;
      }
    }
    .file-version-popup {
      min-width: 30rem;
    }
  }
}

.file-list-column-check {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.column-flex {
  display: flex;
}

.file-sort-arrow {
  width: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .triangle-down {
    @include triangle-dimensions(bottom, $white, 0.313rem, 0.688rem);
    margin-left: auto;
    margin-right: auto;
  }
  .triangle-up {
    @include triangle-dimensions(top, $white, 0.313rem, 0.688rem);
    margin-left: auto;
    margin-right: auto;
  }
}
