// Colors
$white: #ffffff;
$white-smoke: #f5f6f6;
$table-hover-color: #eeeeee;
$table-background-color: #e7e8e8;
$gray-reply: #e4e4e4;
$gray-border: #dee1e2;
$gray-iron: #d4d7d9;
$gray-light: #d1d3d3;
$gray-always-show: #cccccc;
$gray-ash: #b2b8bf;
$gray-icon: #a2a2a2;
$gray-header: #999;
$table-cell-color: #999;
$tool-tip-color: #999;
$gray-oslo: #8a8d90;
$gray-taupe: #888b8d;
$gray: #828588;
$dark-gray-border: #666;
$gray-medium: #63676b;
$gray-dim: #63666a;
$gray-focused: #4f5154;
$gray-dark-slate: #33495f;
$gray-dark: #323435;
$gray-black: #222222;
$black: #000000;
$blue-selected-cell: #abe6ff;
$blue-disabled: #a6d1eb;
$blue-border: #76bad7;
$blue-light-border: #3599c5;
$blue: #159bd7;
$blue-curious: #1496cf;
$blue-dark: #027fb6;
$blue-medium: #015b8f;
$blue-light: #00b2ff;
$blue-menu: #009cde;
$blue-midnight: #003366;
$blue-triangle-open: #0274a6;
$red-error-grid: #e41b48;
$red: #e31e26;
$green-light: #67e55c;
$green: #3f9c46;
$green-haze: #009966;
$green-caribbean: #00cc99;
$green-forest: #3f9c35;
$orange: #f1b134;
$body-color: $gray-dark;
$body-bg: $white;
// Z-Indices
$two-z-index: 2;
$footer-z-index: 2000;
$left-nav-content-z-index: 5000;
$left-nav-z-index: 5500;
$bottom-drawer-content-z-index: 9000;
$bottom-drawer-z-index: 9001;
$top-nav-z-index: 10000;
$search-box-z-index: 10001;
$sidebar-z-index: 10002;
$top-nav-profile-menu-tip-z-index: 10003;
$tool-tip-z-index: 20000;
$date-picker-z-index: 99999 !important;
$warning-modal-z-index: 20000000;
$zero-z-index: 0;
// Grid dropdown
$grid-background: #b6e5fc;
$grid-dropdown-background: #347eb1;
// Options
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret: true !default;
$enable-rounded: false !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-grid-classes: true !default;
$enable-print-styles: false !default;
// Fonts
$font-size-base: 1rem !default;
$h1-font-size: $font-size-base * 1.5 !default;
$h2-font-size: $font-size-base * 1.3 !default;
$h3-font-size: $font-size-base * 1.2 !default;
$h4-font-size: $font-size-base * 1.1 !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base !default;
$font-size-small: $font-size-base * 0.875 !default;
$font-size-xs: $font-size-base * 0.8125 !default;
$font-size-xxs: $font-size-base * 0.75 !default;
$font-family-base: 'PreloBook' !default;
$font-family-bold: 'PreloBold';
$font-family-light: 'PreloLight';
$font-family-italics: 'PreloItalics';
$headings-font-family: 'PreloBookBold' !default;
$headings-font-weight: normal;
//// Forms
// Bootstrap overrides
$input-color: $gray-medium;
$input-border-color: $gray-dark;
$input-focus-border-color: $blue;
$input-disabled-opacity: 0.4;
$form-group-margin-bottom: 0;
// Custom
$label-margin-bottom: 0;
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$navbar-height: 3rem;
$navbar-height-lg: 4rem;
$navbar-width: 3rem;
$navbar-width-lg: 4rem;
$navbar-width-xl: 5rem;
$navbar-content-width: 17.5rem;
$left-nav-min-height: 46.875rem;

$bottom-drawer-height: 3.25rem;

$task-button-height: 3.75rem;
