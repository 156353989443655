@import "../../scss/tsamixins";
@import "~bootstrap/scss/mixins";

.address {
  .form-group {
    margin-bottom: 0.75rem;
    &.address-city {
      width: 100%;
    }
    &.address-state {
      width: 100%;
    }
    &.address-zip {
      width: 100%;
    }
    &.address-county {
      width: 100%;
    }
    &.address-country {
      width: 100%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .address {
    .form-group {
      &.address-city {
        flex: 35;
      }
      &.address-state {
        flex: 25;
        margin-left: 1.5rem;
      }
      &.address-zip {
        flex: 15;
        margin-left: 1.5rem;
      }
      &.address-county {
        width: 50%;
      }
      &.address-country {
        width: 45%;
      }
    }
  }
}

.question .address {
  margin-top: 1rem;
}
