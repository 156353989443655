/* 
 * Misc utility classes. Try not to do this. It 
 * creates a mess of global classes. Some of these
 * already duplicate or conflict with Bootstrap.
 * Just add component level styles were required.
 * If you really feel like you need a global style 
 * check to see if Bootstrap has defined one already.
 */

.flex-end {
  align-items: flex-end;
}

.clickable {
  cursor: pointer;
}

.full-height {
  min-height: 100%;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.hover-to-full-opacity {
  background: rgba($blue, 0.8) !important;
  &:hover {
    background: $blue !important;
  }
}

.nowrap {
  white-space: nowrap;
}

.react-icons {
  vertical-align: middle;
}

.inline {
  display: inline;
}

.bold {
  @include font-family-bold();
}
