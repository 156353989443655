@import "../../scss/tsamixins";
@import "~bootstrap/scss/mixins";

.engagement-section {
  overflow-x: hidden;

  h3 {
    padding: 0.5rem 1rem;
    margin-top: 1rem;

    @include media-breakpoint-up(xl) {
      padding: 1rem 2rem;
    }
  }

  .icon.check {
    font-size: 1.25rem;
    margin-right: 0.4rem;
    color: $green;
    margin-bottom: 0.2rem;
  }
}
