@import 'tsavariables';
@mixin font-family-base {
  font-family: $font-family-base;
  font-weight: normal;
  font-style: normal;
}

@mixin font-family-bold {
  font-family: $font-family-bold;
  font-weight: normal;
  font-style: normal;
}

@mixin font-family-light {
  font-family: $font-family-light;
  font-weight: normal;
  font-style: normal;
}

@mixin font-family-italics {
  font-family: $font-family-italics;
  font-weight: normal;
  font-style: normal;
}

@mixin text-one-line-ellipsis-truncation {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color
/// @param {Length} $size [1em] - Triangle size
@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  width: 0;
  height: 0;
  content: '';
  z-index: $two-z-index;
  border-#{opposite-position($direction)}: ($size * 1.5) solid $color;

  $perpendicular-borders: $size solid transparent;

  @if $direction == top or $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
  }
}

/// Triangle Dimensions helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color
/// @param {Length} $size [1em] - Triangle size
@mixin triangle-dimensions(
  $direction,
  $color: currentcolor,
  $height: 1em,
  $width: 1em,
  $z-index: $two-z-index
) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  width: 0;
  height: 0;
  content: '';
  z-index: $z-index;
  border-#{opposite-position($direction)}: ($height) solid $color;

  $perpendicular-borders: ($width * 0.5) solid transparent;

  @if $direction == top or $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
  }
}

// Add outward pointing arrows (triangles) to the sides of a box that has borders. (CSS triangles obviously
// based on Chris Coyier's CSS triangle. http://css-tricks.com/snippets/css/css-triangle.)
//
//  Parameters:
//
//  * `direction` -- What side of the box and direction of the arrow
//  * `flatten` -- An *even numbered* factor of flattening the triangle (MUST be a even number)
//  * `arrowBaseLength` -- Width or height of the arrow's base
//  * `borderWidth` -- Width of the arrow's border
//  * `backgroundColor` -- Color of the arrow's background
//  * `borderColor` -- Color of the arrow's border
@mixin bordered-box-arrow(
  $direction: left,
  $flatten: 2,
  $arrowBaseLength: 16px,
  $borderWidth: 1px,
  $backgroundColor: red,
  $borderColor: white
) {
  // Directional constants
  $directionOpposite: opposite-position($direction);
  $directionPerpendicular: null;
  @if $direction == top or $direction == bottom {
    $directionPerpendicular: left;
  }
  @if $direction == left or $direction == right {
    $directionPerpendicular: top;
  }
  $directionPerpendicularOpposite: opposite-position($directionPerpendicular);

  // Le math
  $baseBorder: $arrowBaseLength * (1 / $flatten);
  $drop: $baseBorder * -1;
  $adjust: ($arrowBaseLength + $borderWidth * 2) * -1;

  // Force relatively positioned container
  position: relative !important;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    #{$directionPerpendicular}: 50%;
    margin-#{$directionPerpendicular}: $adjust;
    border: {
      #{$directionPerpendicular}: $arrowBaseLength solid transparent;
      #{$directionPerpendicularOpposite}: $arrowBaseLength solid transparent;
    }
    border-#{$directionOpposite}: {
      width: $baseBorder;
      style: solid;
    }
  }

  &:before {
    #{$direction}: $drop;
    border-#{$directionOpposite}-color: $borderColor;
  }

  &:after {
    #{$direction}: $drop + $borderWidth;
    border-#{$directionOpposite}-color: $backgroundColor;
  }
}

/// Returns the opposite direction of each direction in a list
/// @author Hugo Giraudel
/// @param {List} $directions - List of initial directions
/// @return {List} - List of opposite directions
@function opposite-position($directions) {
  $opposite-positions: ();
  $direction-map: (
    'top': 'bottom',
    'right': 'left',
    'bottom': 'top',
    'left': 'right',
    'center': 'center',
    'ltr': 'rtl',
    'rtl': 'ltr',
  );

  @each $direction in $directions {
    $direction: to-lower-case($direction);

    @if map-has-key($direction-map, $direction) {
      $opposite-positions: append(
        $opposite-positions,
        unquote(map-get($direction-map, $direction))
      );
    } @else {
      @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
  }

  @return $opposite-positions;
}

@function decimal-round($number, $digits: 0, $mode: round) {
  $n: 1;
  // $number must be a number
  @if type-of($number) != number {
    @warn '#{ $number } is not a number.';
    @return $number;
  }
  // $digits must be a unitless number
  @if type-of($digits) != number {
    @warn '#{ $digits } is not a number.';
    @return $number;
  } @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';
    @return $number;
  }
  @if $digits > 0 {
    @for $i from 1 through $digits {
      $n: $n * 10;
    }
  }
  @if $mode == round {
    @return round($number * $n) / $n;
  } @else if $mode == ceil {
    @return ceil($number * $n) / $n;
  } @else if $mode == floor {
    @return floor($number * $n) / $n;
  } @else {
    @warn '#{ $mode } is undefined keyword.';
    @return $number;
  }
}
