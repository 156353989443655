@import 'tsavariables';
@import 'tsamixins';

// Fonts
@font-face {
  font-family: 'PreloBook';
  src: url('../fonts/Prelo-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PreloBold';
  src: url('../fonts/Prelo-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PreloLight';
  src: url('../fonts/Prelo-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PreloItalics';
  src: url('../fonts/Prelo-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Use the correct font families for em and strong. Avoids getting fake bold and fake italics. */
em {
  @include font-family-bold();
}

strong {
  @include font-family-bold();
}

/* Override the reboot of font-weight on body. */
body {
  font-weight: normal;
}
