@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
@import '~bootstrap/scss/mixins';

$file-error-color: darken($red, 12%);
.file-upload {
  color: $white;
  background: $gray-taupe;
  padding: 1rem 1.25rem;
  display: flex;
  flex-direction: column;
  .tsa-alert-error {
    .tsa-alert-icon {
      stroke: $file-error-color;
      fill: $file-error-color;
    }
  }
  .tsa-alert-text {
    .tsa-alert-title {
      @include font-family-base();
    }
    color: $white;
  }

  .file-upload-scrollable {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-basis: 0;
  }
  .file-upload-button-region {
    flex-grow: 0;
    flex-shrink: 0;
    .file-upload-btn {
      margin-top: 1rem;
      color: $white;
      background: $blue-dark;
      height: 3.75rem;
      font-size: 1.25rem;
      text-transform: uppercase;
      letter-spacing: 0.056rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon.loading {
        margin-left: 0.5rem;
        fill: $white;
        stroke: $white;
      }
      &.busy {
        opacity: 1;
      }
    }
  }
}

.file-upload-header {
  @include font-family-light();
  font-size: 1rem;
  line-height: 1.25;
  letter-spacing: 0.031rem;
  margin-bottom: 1.25rem;
  svg.close {
    height: 1.25rem;
    width: 1.25rem;
    stroke: $white;
    fill: $white;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.file-upload-title {
  .status-text {
    color: $white;
  }
  input {
    margin-top: 0;
  }
  @include media-breakpoint-down(md) {
    .form-group {
      label {
        width: 50%;
        float: left;
      }
      .status-text-area {
        width: 50%;
        float: right;
      }
    }
  }
}

.file-upload-dropzone {
  margin: 1rem 0;
  font-size: 0.75rem;
  .dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $gray-dim;
    padding: 1rem;
    font-size: 1.5rem;
    cursor: pointer;

    .helper-text {
      @include font-family-italics();
      font-size: 1rem;
      line-height: 1rem;
      text-align: center;
      padding-bottom: 1rem;
    }
  }
  .dropzone-active {
    background: $blue-dark;
  }
  .dropzone-disabled {
    opacity: 0.4;
    cursor: default;
  }
  .documents {
    font-size: 2rem;
    stroke: $gray-dim;
    fill: $white;
  }
  .select-text {
    font-size: 0.85rem;
    line-height: 1rem;
  }
  .file-upload-max-files {
    @include font-family-bold();
  }
}

.file-upload-files {
  margin-bottom: 0.5rem;
  overflow-y: auto;
  min-height: 2rem;
  flex-grow: 1;
  flex-basis: 0;
}

.file-upload-file {
  font-size: 0.88rem;
  line-height: 1.75rem;
  @include text-one-line-ellipsis-truncation();
  .icon.delete {
    stroke: $white;
    fill: $white;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    margin-bottom: 0.188rem;
    cursor: pointer;
  }
  &.rejected {
    color: $file-error-color;
  }
}

.sidebar-content-file {
  .file-upload {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .file-upload-dropzone {
    display: flex;
    flex-direction: column;
  }
  @include media-breakpoint-up(sm) {
    .file-upload-dropzone {
      flex: 1;
      .dropzone {
        flex: 1;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .file-upload-dropzone .dropzone {
    line-height: 4rem;

    span {
      text-align: center;
      width: 100%;
    }
  }
  .file-upload-dropzone .documents {
    font-size: 5rem;
  }
  .sidebar-content-file {
    .file-upload-dropzone {
      flex: 1;
      .dropzone {
        flex: 1;
      }
    }
  }
}
