@import '../../scss/tsavariables';
@import '~bootstrap/scss/mixins';
$sidebar-width: 20rem;
$sidebar-width-xl: 25rem;
$sidebar-closed-width: 2.5rem;
$sidebar-background-color: $white-smoke;
.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  .icon.isClosed {
    transform: rotate3d(0, 0, 1, -90deg);
  }
}

.sidebar-expander {
  padding: 0.375rem 0.5rem;
  cursor: pointer;
  line-height: 1;
  &:hover {
    background-color: $table-hover-color;
  }
}

.sidebar-tray {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding-top: 4rem;
  font-size: 1.5rem;
  opacity: 0.5;
  cursor: pointer;
}

.sidebar,
.sidebar-content-container {
  width: $sidebar-width;
  min-width: $sidebar-width;
  background-color: $sidebar-background-color;
}

.sidebar-content-container {
  position: absolute;
  top: 2.125rem;
  bottom: 0;
  left: 0;
}

@include media-breakpoint-up(xl) {
  .sidebar,
  .sidebar-content-container {
    width: $sidebar-width-xl;
    min-width: $sidebar-width-xl;
  }
}

$sidebar-animation-duration: 0.3s;
@function duration($percent) {
  @return $sidebar-animation-duration * $percent;
}

.sidebar.sidebar-exit {
  // shrink the width of the sidebar
  transition: all duration(0.9) ease-in;
  .sidebar-content-container,
  .sidebar-tray {
    // followed by sliding the content container to the right
    transition: all duration(0.1) linear duration(0.9);
  }
}

.sidebar.sidebar-exit-active,
.sidebar.sidebar-exit-done {
  width: $sidebar-closed-width;
  min-width: $sidebar-closed-width;
  .sidebar-content-container {
    left: $sidebar-closed-width;
  }
  .sidebar-tray {
    opacity: 1;
  }
}

.sidebar.sidebar-exit-done .icon.isClosed {
  transform: rotate3d(0, 0, 1, 90deg);
}

.sidebar.sidebar-enter {
  .sidebar-content-container,
  .sidebar-tray {
    // slide the content container to the left
    transition: all duration(0.1) linear;
  } // followed by expanding the width of the sidebar
  transition: all duration(0.9) ease-out duration(0.1);
}
