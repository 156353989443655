@import "../../scss/tsavariables";
.bottom-drawer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $white-smoke;
  border-top: 1px solid $gray-border;
  height: $bottom-drawer-height;
  z-index: $bottom-drawer-z-index;
}

.bottom-drawer-content {
  position: absolute;
  width: 100%;
  bottom: $bottom-drawer-height;
  background-color: $white-smoke;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.3s ease;
  z-index: $bottom-drawer-content-z-index;
  &.drawer-open {
    top: 0;
    transform: translate3d(0, 0, 0);
  }
  .question-help.read-more {
    top: 2rem;
  }
}

.bottom-drawer-close {
  padding: 0.5rem 0;
  cursor: pointer;
  line-height: 1;
  text-align: center;
  border-bottom: 1px solid $gray-border;
  &:hover {
    background-color: $table-hover-color;
  }
}
