@import "../../scss/tsavariables";
@import "../../scss/tsamixins";
@import "~bootstrap/scss/mixins";

.debug-header {
  align-items: center;
  background-color: $blue-dark;
  color: white;
  display: flow-root;
  font-family: $font-family-light;
  font-size: 2rem;
  padding: 1rem 1rem 1rem 2rem;
}

#debug.container > .row {
  padding-bottom: 2rem;
}

#debug {
  .clickable {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    cursor: pointer;
  }
  .engagement-dropdown-menu-list {
    color: $gray-black !important;
    a:hover {
      color: $white !important;
    }
  }
}
