@import '../../scss/tsamixins';
@import '~bootstrap/scss/mixins';

.comment-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.1875rem;

  @include media-breakpoint-down(xs) {
    height: 7rem;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  .filter {
    margin-right: 0.5rem;
  }

  .savingIcon {
    @include media-breakpoint-down(xs) {
      display: none !important;
    }
  }
}
