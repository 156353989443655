$background-color: rgb(97, 97, 97);

.ClientGroupDropdown {
  margin: 0;
  display: inline-block;
  color: white;
  cursor: pointer;

  .menu-button {
    padding: 0;
    margin: 5px;
  }

  .filter-text {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.client-group-dropdown-sidebar.p-sidebar {
  > * {
    background-color: $background-color;
  }

  .client-side-bar-label {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    font-size: 0.813rem;
    color: #ffffff;
    letter-spacing: 0.041rem;
  }

  .p-inputtext {
    background-color: white;
    color: black;
    margin-bottom: 0.5rem;
  }
}

.client-group-dropdown-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .p-slidemenu.p-component {
    height: 100% !important;
    width: 100% !important;
    background-color: $background-color;

    .p-slidemenu-wrapper {
      height: 100% !important;
      background-color: $background-color;
    }
  }
}
