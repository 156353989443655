@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
@import '~bootstrap/scss/mixins';
.progress-save {
  display: flex;
  align-items: center;
  .checkedCircle {
    height: 3rem;
    width: 3rem;
    stroke: $green;
    stroke-width: 2px;
  }
  .checkedCircle.under-review {
    fill: $green;
    polyline {
      stroke: $white;
    }
  }
  .saving {
    height: 1.75rem;
    width: 1.75rem;
    stroke: $gray-ash;
    fill: $gray-ash;
  }
  .progress-save-icon {
    margin-right: 0.75rem;
    position: relative;
    .badge {
      background-color: #63666a;
      border-radius: 1rem;
      font-size: 0.625rem;
      position: absolute;
      top: 0.2rem;
      right: -0.2rem;
      width: 1rem;
      height: 1rem;
    }
    .locked,
    .unlocked {
      position: absolute;
      right: 0.164rem;
      top: 0.1rem;
      fill: white;
      stroke: grey;
      stroke-width: 28.4px;
    }
  }
  .last-saved-message {
    font-size: 0.5rem;
    text-transform: uppercase;
  }
  .progress-message {
    font-size: 0.938rem;
    color: $green;
    text-transform: uppercase;
  }
  .progress-done-button {
    text-transform: capitalize;
  }
}
