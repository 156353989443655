@import '../../scss/tsamixins';
@import '../../scss/tsavariables';
@import '~bootstrap/scss/mixins';

$margin-left: 1rem;
$margin-left-big: 2rem;

.engagement-reports {
  @include media-breakpoint-down(lg) {
    margin-left: $margin-left;
  }

  @include media-breakpoint-up(xl) {
    margin-left: $margin-left-big;
  }

  margin-top: 1.9375rem;
  color: $gray-dark;
  letter-spacing: 0.05rem;
  flex-grow: 1;
  overflow-x: none;
  @include font-family-light();

  .engagement-report-item {
    .engagement-report-item-button {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      margin-top: 1rem;
      * {
        margin-left: 1rem;
        &:first-child {
          margin-left: 0;
        }
      }

      button {
        height: 3.25rem;
        margin-top: 1rem;
        border-radius: 0.25rem;
        text-transform: uppercase;
        background-color: $blue-menu;
        min-width: 8.5rem;
        white-space: normal;
        margin-top: 0;
        padding: 0.375rem 0.75rem;
      }
    }
  }
}
