@import "../../scss/tsavariables";
$layout-gap: 8px;
.page-footer {
  display:flex;
  align-items: center;
  font-size:.85em;
  //background-color: $gray-medium;
  &_meta {
      //margin:$layout-gap * 2 0;
      padding-left:$layout-gap * 2;
      flex-grow:1;
      color: var(--cui-color-muted);        
      p{
          margin:0;
          margin-bottom:4px;
          margin-top:4px;
      }
      a{
        margin-left:1rem;
      }

    
  }

}