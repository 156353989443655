@import '~bootstrap/scss/mixins';
@import '../../scss/tsamixins';
@import '../../scss/tsavariables';

.inline-comment {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  textarea {
    background-color: $gray-reply;
    border: 1px solid $gray;
    border-radius: 0.4rem;
    padding: 0.438rem 0.75rem;
    flex: 1 1 auto;
    &:focus {
      outline: none;
      border-color: $blue;
    }
  }
  .icon {
    stroke: $gray;
    fill: $gray;
    margin-left: 0.5rem;
    cursor: pointer;
  }
}
