@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
@import '~bootstrap/scss/mixins';

.assistance {
  font-size: 0.9rem;
  .title {
    @include font-family-bold();
    font-size: 1rem;
  }
  .assistance-group {
    margin-top: 2rem;
    margin-left: 2rem;
  }
}
