@import "../../scss/tsavariables";
@import "~bootstrap/scss/mixins";
.left-nav {
  display: flex;
  width: $navbar-width;
  min-width: $navbar-width;
}

.left-nav-items {
  flex-grow: 1;
  color: $white;
  background-color: $gray-medium;
  overflow: hidden;
  text-align: center;
  font-size: 2rem;
  z-index: $left-nav-z-index;
}

.left-nav .left-nav-items .icon {
  fill: none;
  stroke: $white;
  &.flags {
    fill: $white;
    stroke: none;
  }
  &.carryForward {
    stroke-width: 0.5em;
  }
}

.left-nav .left-nav-items button {
  position: relative;
  color: $white;
  display: block;
  cursor: pointer;
  padding-bottom: 0.5rem;
  transition: background-color 0.15s ease;
  border-bottom: 1px solid $gray;
  width: 100%;
  &:hover {
    color: $white;
    background-color: $gray-oslo;
    text-decoration: none;
  }
  &.active {
    background-color: $blue;
    .badge {
      background-color: $gray-dim;
    }
  }
  .title {
    display: none;
    font-size: 0.625rem;
  }
}

.left-nav-items .badge {
  color: $white;
  background-color: $gray-icon;
  display: inline-block;
  padding: 0.188rem 0;
  line-height: 1;
  border-radius: 1.125em;
  bottom: 0.5rem;
  position: absolute;
  right: 0.25rem;
  font-size: 0.625rem;
  width: 1.5rem;
  transition: background-color 0.15s ease;
}

.left-nav-content {
  background-color: $blue;
  position: absolute;
  left: $navbar-width;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translate3d(-100%, 0, 0) translate3d(-#{$navbar-width}, 0, 0);
  transition: transform 0.3s ease;
  z-index: $left-nav-content-z-index;
}

.left-nav.open .left-nav-content {
  transform: translate3d(0, 0, 0);
}

@include media-breakpoint-up(sm) {
  .left-nav-content {
    max-width: $navbar-content-width;
  }
}

@include media-breakpoint-up(lg) {
  .left-nav-items {
    flex: 0 0 $navbar-width;
  }

  @media screen and (min-height: $left-nav-min-height) {
    .left-nav {
      width: $navbar-width-lg;
      min-width: $navbar-width-lg;
    }
    .left-nav .left-nav-items {
      flex: 0 0 $navbar-width-lg;
      font-size: 2.75rem;
      button .title {
        display: block;
      }
      .badge {
        bottom: 1.6rem;
        width: 1.75rem;
      }
    }
    .left-nav-content {
      left: $navbar-width-lg;
      transform: translate3d(-100%, 0, 0)
        translate3d(-#{$navbar-width-lg}, 0, 0);
    }
  }
}

@include media-breakpoint-up(xl) {
  .left-nav {
    position: relative;
    transition: all 0.3s ease;
  }
  .left-nav .left-nav-items {
    flex: 0 0 $navbar-width;
  }
  .left-nav.open {
    width: $navbar-width + $navbar-content-width;
    min-width: $navbar-width + $navbar-content-width;
  }
  .left-nav-content {
    transform: none;
    left: auto;
    width: $navbar-content-width;
  }

  @media screen and (min-height: $left-nav-min-height) {
    .left-nav {
      width: $navbar-width-xl;
      min-width: $navbar-width-xl;
    }
    .left-nav .left-nav-items {
      flex: 0 0 $navbar-width-xl;
      font-size: 2.75rem;
      button .title {
        display: block;
      }
      .badge {
        bottom: 1.6rem;
        width: 1.75rem;
      }
    }
    .left-nav.open {
      width: $navbar-width-xl + $navbar-content-width;
      min-width: $navbar-width-xl + $navbar-content-width;
    }
  }
}
