@import '../../scss/tsamixins';
@import '~bootstrap/scss/mixins';
.text-check {
  .form-group {
    margin-bottom: 0.75rem;
    &.textboxcheckbox-text {
      width: 100%;
    }
    &.textboxcheckbox-check {
      width: 100%;
    }
  }
}
