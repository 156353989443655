@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
@import '~bootstrap/scss/mixins';
$transition: all 0.15s ease-in-out;
$doc-library-item-padding: 0.75rem;
.doc-library-item {
  border-radius: 0.25rem;
  border: 0.063rem solid $gray-iron;
  background-color: $white-smoke;
  margin-right: 2rem;
  margin-bottom: 1rem;
  transition: $transition;
  .question:hover {
    background: none;
  }
}

.doc-library-item-header {
  display: flex;
  align-items: center;
  > div {
    line-height: 1.1;
  }
  .icon {
    cursor: pointer;
  }
}

.doc-library-item-expander,
.doc-library-item-actions {
  display: flex;
  align-items: center;
}

.doc-library-item-expander {
  flex: 1 1 auto;
  cursor: pointer;
  .file-links {
    display: flex;
    flex-wrap: wrap;
    .a {
      margin-right: 0.8rem;
      font-size: 0.75rem;
    }
  }
  .icon.isClosed {
    height: 1.5rem;
    width: 1.5rem;
    margin: 0.75rem;
    stroke: $gray-icon;
    fill: $gray-icon;
    transition: $transition;
  }
}

.doc-library-item-actions {
  svg.icon {
    margin-right: 1rem;
  }
  .na-checkbox {
    display: none;
    margin: 0 1rem;
    font-size: 0.75rem;
    margin-top: auto;
    line-height: inherit;
    .form-check-label {
      &::before {
        height: 0.75rem;
        width: 0.75rem;
        top: 0;
        color: $gray-icon;
      }
      &::after {
        left: 2px;
        top: 3px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .doc-library-item-actions {
    .na-checkbox {
      display: block;
    }
  }
}

.doc-library-required-icon {
  width: 1.5rem;
  text-align: center;
  .icon {
    width: 0.5rem;
    height: 0.5rem;
    stroke: none;
    fill: $dark-gray-border;
  }
}

.doc-library-item.missing-required-doc {
  .doc-library-item-content {
    @include font-family-bold();
  }
  .doc-library-required-icon .icon {
    fill: $red;
  }
}

.doc-library-item-count {
  color: $gray;
  font-family: 'PreloLight';
  font-weight: normal;
  font-style: normal;
  font-size: 0.688rem;
  letter-spacing: 0.034rem;
  margin-top: 0.5rem;
}

.doc-library-item.open {
  border-color: $gray-dim;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
  margin-right: 0;
  .icon.isClosed {
    transform: rotate3d(1, 0, 0, 180deg);
  }
  .doc-library-item-header .na-checkbox {
    opacity: 0;
    visibility: hidden;
  }
}

.doc-library-item-details {
  border-left: 0.625rem solid $dark-gray-border;
  border-top: 0.063rem solid $gray-always-show;
}
