@import "../../scss/tsavariables";
@import "../../scss/tsamixins";
@import "~bootstrap/scss/mixins";

.search-box {
  position: relative;
  @include font-family-light();
  background-color: $table-background-color;
  font-size: 0.9rem;

  input.form-control {
    background-color: $table-background-color;
    border: 0;
    box-shadow: none;
    flex: 1;
    overflow-x: hidden;
    padding-right: 0px;
    min-width: 8.75rem;
  }

  .icon {
    margin-bottom: 0.2rem;
    height: 1rem;
    width: 1rem;
    stroke: $gray;
    fill: $gray;
  }
  .icon.search {
    margin: 0 0.5rem;
  }
}

.search-box-results {
  background-color: $table-background-color;
  position: fixed;
  top: $navbar-height;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: $top-nav-z-index;
  font-size: 1.125rem;
}

.search-box-result {
  display: flex;
  align-items: flex-start;
  color: $blue;
  padding: 0.38rem 1rem;
  line-height: 1.2;
  cursor: pointer;
  .icon {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.5rem;
    fill: $blue;
    stroke: $blue;
  }
  .search-text {
    @include font-family-bold();
  }
  .number {
    @include font-family-bold();
    margin-right: 0.4rem;
  }
  &:hover,
  &.selected {
    background: $blue;
    color: $white;
    .icon {
      fill: $white;
      stroke: $white;
    }
  }
}

@include media-breakpoint-up(lg) {
  .search-box-results {
    top: 3.5rem;
    left: 5rem;
    width: 34rem;
    bottom: auto;
    max-height: 90vh;
    font-size: 1rem;
  }
}

@include media-breakpoint-up(xl) {
  .search-box-results {
    left: 7rem;
  }
}

.search-box-link {
  color: $blue-dark;
  background: none;
  border: none;
  &:hover {
    color: $blue-medium;
    text-decoration: underline;
  }
}
