@import "../../scss/tsamixins";
@import "../../scss/tsavariables";
@import "~bootstrap/scss/mixins";

.question-list-buttons {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125rem;
  .btn {
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    padding: 0.75rem;
    display: flex;
    svg {
      font-size: 1.25em;
    }
  }
  .btn .lesser {
    transform: rotate(90deg);
  }
  .btn .greater {
    transform: rotate(-90deg);
  }
  .btn.navigate {
    background-color: $gray-iron;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
  }
  .btn.phase {
    flex: 1;
  }
   // when smaller non fixed question list buttons,we show the standard footer and hide the footer2
  .footer2visible {
    visibility: hidden
  }

  @include media-breakpoint-up(xl) {
    position: fixed;
    bottom:0px;
    //max-width: 78%;
    //height: 15%;
    width: 100%;
    // padding-left: 2rem;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    background-color: $white;
    .btn {
      margin-right: 1rem;
      margin-bottom: 0;
      padding: 0.5rem 0.75rem;
    }
    .btn.navigate {
      width: auto;
      min-width: 8rem;
    }
    .btn.phase {
      flex: 0;
      min-width: 14rem;
    }
    // when large we show footer2 part of the questionlist button component.
    .footer2visible {
      visibility:visible 
    }
  }
}
