@import '../../scss/tsamixins';
@import '../../scss/tsavariables';

.filter-sort-dropdown {
  position: relative;

  .label {
    font-size: 0.813rem;
    color: $white;
    letter-spacing: 0.041rem;
    padding-bottom: 0.5rem;
  }
}

.filter-sort-button {
  display: flex;
  position: relative;
  flex-direction: row;
  height: 2.5rem;
  min-width: 10.063rem;
  background-color: $blue-triangle-open;
  align-items: center;
  border-radius: 0.25rem;
  border: 0.063rem solid $blue-border;
  cursor: pointer;

  .button-label {
    flex-grow: 1;
    font-size: 0.813rem;
    user-select: none;
    text-align: left;
    letter-spacing: 0.057rem;
    color: $white;
    display: flex;
    align-items: center;

    .value {
      text-transform: uppercase;
      margin-left: 0.6875rem;
      margin-right: 0.6875rem;
    }
  }

  .divider {
    width: 0.125rem;
    height: 100%;
    background-color: $blue-border;
  }

  .arrow {
    height: 100%;
    width: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .triangle-down {
      @include triangle-dimensions(bottom, $white, 0.313rem, 0.688rem);
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.open {
    border-radius: 0.25rem 0.25rem 0 0;
    background-color: $blue-medium;
  }
}

.filter-sort-items {
  position: absolute;
  z-index: 3;
  width: 100%;
  overflow: hidden;
  font-size: 0.813rem;
  letter-spacing: 0.056rem;
  border-radius: 0 0 0.25rem 0.25rem;
  right: 0;

  .selected {
    @include font-family-bold();
  }

  .tcont {
    color: $white;
    background-color: $blue-medium;
    padding: 0 0.6875rem 1.4rem 0.6875rem;

    & > div {
      padding-top: 1rem;
      cursor: pointer;
      user-select: none;
    }
  }
  &.open {
    border: 0.063rem solid $blue-border;
  }
}
