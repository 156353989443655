body,
html,
#root {
  height: 100%;
  overflow: hidden;
}

#modal-root {
  position: fixed;
  z-index: $warning-modal-z-index;
}

.main-bar {
  display: flex;
  position: absolute;
  width: 100%;
  top: $navbar-height;
  bottom: $bottom-drawer-height;
}

.entity-nav-header {
  padding: 0.5rem 0 0 0.5rem;
}

.entity-nav-header + .main-bar {
  top: $navbar-height + 2.5;
}

.page-content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: auto;

  .page-content-superheader {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .page-content-header {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .page-content-body {
    overflow: auto;
    flex-grow: 1;
    flex-shrink: 0;
    overflow-y: hidden;
  }
}

@include media-breakpoint-down(sm) {
  html {
    font-size: 14px;
  }
}

@include media-breakpoint-up(lg) {
  .main-bar {
    top: $navbar-height-lg;
    bottom: 0;
  }

  .entity-nav-header {
    padding-left: 1.5rem;
  }

  .entity-nav-header + .main-bar {
    top: $navbar-height-lg + 2.5;
  }
}

@include media-breakpoint-up(xl) {
  .entity-nav-header {
    padding-left: 2.5rem;
  }
}
