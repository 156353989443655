@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
@import '~bootstrap/scss/mixins';

.email-send-menu {
  position: relative;
  .email-send-menu-button {
    color: $white;
    background-color: $blue-menu;
    height: 2.375rem;
    width: 2.375rem;
    border-radius: 2.375rem;
    padding: 0;
    @include font-family-bold();
    &:active:focus,
    &:focus {
      box-shadow: none;
      outline: 0;
    }

    .badge {
      background-color: $gray-dim;
      border-radius: 1rem;
      font-size: 0.625rem;
      position: absolute;
      top: 0.2rem;
      right: -0.2rem;
      width: 1rem;

      &.ten {
        width: 1.2rem;
        right: -0.5rem;
      }
      &.over {
        width: 1.65rem;
        right: -0.75rem;
      }
    }
  }
}
