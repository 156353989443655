@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
$bottom-drawer-small-icon-size: $bottom-drawer-height * 0.5;
$bottom-drawer-large-icon-size: $bottom-drawer-height * 0.7;
$bottom-drawer-flag-scale-factor: 1.05;
.engagement-bottom-drawer.bottom-drawer {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  color: $gray-dark-slate;
  font-size: $bottom-drawer-small-icon-size;
  .engagement-bottom-drawer-left,
  .engagement-bottom-drawer-right {
    display: flex;
    align-items: center;
  }
  .engagement-bottom-drawer-left {
    font-size: $bottom-drawer-large-icon-size;
  }
  .icon {
    vertical-align: top;
    margin-right: 1rem;
    height: 100%;
    cursor: pointer;
  }
  .icon:last-child {
    margin-right: 0;
  }
}

.engagement-bottom-drawer .circleBigCheck {
  fill: none;
  stroke: $green;
  stroke-width: 0.8em;
  &.active {
    fill: $green;
  }
}

// TODO: Please get an icon that has a border around it!
.engagement-bottom-drawer .flag-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: decimal-round(
    $bottom-drawer-large-icon-size * $bottom-drawer-flag-scale-factor,
    2
  );
  width: decimal-round(
    $bottom-drawer-large-icon-size * $bottom-drawer-flag-scale-factor,
    2
  );
  border-radius: decimal-round($bottom-drawer-large-icon-size / 1.8, 2);
  border: 2px solid $blue;
  .icon {
    font-size: $bottom-drawer-large-icon-size * 0.5;
    fill: $blue;
  }
  &.active {
    background-color: $blue;
    .icon {
      fill: $white;
    }
  }
}

.engagement-bottom-drawer.bottom-drawer-content {
  display: flex;
  flex-direction: column;
  .activity-list,
  .file-upload {
    flex-grow: 1;
  }
  .activity-list,
  .question-help {
    padding-top: 1rem;
  }
}
