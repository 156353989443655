@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
.check-boxes {
  .form-check-input {
    margin-left: -999rem;
  }
  &.form-group label {
    display: inline-block;
  }
}

.check-box-input label {
  margin-top: 1rem;
}

.check-box-input input {
  margin-top: 0.2rem !important;
}

.checkbox input[type='checkbox'] {
  opacity: 0;
}

.checkbox label {
  position: relative;
  display: inline-block;
  padding-left: 22px;
}

.checkbox label::before,
.checkbox label::after {
  position: absolute;
  content: '';
  /*Needed for the line-height to take effect*/
  display: inline-block;
}

.checkbox label::before {
  height: 1rem;
  width: 1rem;
  border: 1px solid;
  left: 0px;
  top: 1px;
  background-color: $white;
}

.checkbox label::after {
  height: 5px;
  width: 9px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-45deg);
  left: 4px;
  top: 5px;
}

.checkbox input[type='checkbox'] + label::after {
  content: none;
}

.checkbox input[type='checkbox']:checked + label::after {
  content: '';
}

.selected {
  .check-box-input.is-invalid {
    label::before {
      border-color: $red;
    }
  }
  .check-box-input.is-valid {
    label::before {
      border-color: $green;
    }
  }
}
