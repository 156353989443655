@import '../../scss/tsamixins';
@import '~bootstrap/scss/mixins';

.name {
  .form-group {
    margin-bottom: 0.75rem;
    &.name-prefix,
    &.name-suffix {
      width: 25%;
    }
    &.name-first {
      width: 100%;
    }
    &.name-middle {
      width: 25%;
    }
    &.name-last {
      width: 100%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .name {
    .form-group {
      margin-bottom: 0.75rem;
      &.name-prefix,
      &.name-suffix {
        width: 25%;
      }
      &.name-first {
        flex: 2;
      }
      &.name-middle {
        flex: 1;
        margin-left: 1.5rem;
      }
      &.name-last {
        flex: 2;
        margin-left: 1.5rem;
      }
    }
  }
}

.question .name {
  margin-top: 1rem;
}
