@import "~bootstrap/scss/mixins";
@import "../../scss/tsamixins";
@import "../../scss/tsavariables";

.setup-header {
  align-items: center;
  background-color: $blue-dark;
  color: white;
  display: flow-root;
  font-family: $font-family-light;
  font-size: 2rem;
  padding: 1rem 1rem 1rem 2rem;
}

.setup {
  height: 100%;

  .content {
    padding-left: 2rem;
    padding-top: 1rem;
  }

  .content-container {
    width: 100%;
  }

  .data-grid-holder {
    position: relative !important;
  }

  .heading {
    font-family: $font-family-bold;
  }

  hr {
    border: none;
    border-top: 1px solid #333;
    color: #333;
    width: 100%;
  }

  .leftBar {
    background-color: $gray-medium;
    height: 100%;
    min-width: 3.8rem;

    @include media-breakpoint-up(lg) {
      min-width: 5rem;
    }
  }

  .loader {
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .sidebar {
    background-color: $gray-taupe;
  }

  .summary-header {
    margin-right: 1rem;
    width: 61.5rem;
  }

  .summary-link {
    font-size: unset;
    width: fit-content;
  }

  .summary-header-download-template-link {
    text-align: right;
    padding: 0.375rem 0.75rem;
  }

  .engagement-management-link,
  .summary-link {
    background: none !important;
    border: none;
    padding: 0 !important;
    cursor: pointer;
    color: $blue-menu;
  }
  .engagement-management-link:hover,
  .summary-link:hover {
    text-decoration: underline;
  }
}
