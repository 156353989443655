@import '../../scss/tsavariables';
.sidebar.engagement-sidebar .sidebar-content-container {
  display: flex;
  flex-direction: column;
}

.engagement-sidebar {
  .sidebar-tray {
    color: $gray-icon;
  }
  .sidebar-tray .icon {
    margin-bottom: 1rem;
    line-height: 1;
  }
  .sidebar-tray .icon.document {
    fill: currentColor;
    stroke: $white-smoke;
  }
}

.engagement-sidebar {
  .activity-list,
  .file-upload {
    flex-grow: 1;
  }
}
