@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-body {
  position: relative;
  background-color: $white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  width: 85%;
  max-width: 37.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .close {
    position: absolute;
    top: 0.375rem;
    right: 1rem;
    font-size: 2rem;
    color: $blue;
  }

  &.custom-email {
    max-width: 100rem;
    overflow-y: auto;
    overflow-x: hidden;
    height: 85%;
    flex-grow: 1;
    flex-shrink: 0;
    display: block;

    .email-settings {
      flex-grow: 1;
      flex-shrink: 0;
      width: 100%;
      overflow: hidden;

      .email-defined-settings-region {
        flex-grow: 1;
        flex-shrink: 0;
      }

      & > .email-value {
        margin-top: 1rem;
      }

      .email-body-header {
        @include font-family-bold();
        margin-top: 3rem;
      }
    }
    .email-defined-footer,
    .email-defined-settings {
      display: flex;
      align-items: center;
      width: 100%;
      flex-grow: 0;
      flex-shrink: 0;

      .cancel-email {
        margin-left: auto;
        margin-right: 1rem;
        color: $blue;
        cursor: pointer;
        user-select: none;
        background: none;
        border: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .send-email {
        border-radius: 0.5rem;
        text-transform: uppercase;
        padding: 0.5rem 4rem 0.5rem 4rem;

        svg.icon {
          fill: $white;
          height: 1.5rem;
          width: 1.5rem;
        }
      }

      .email-header {
        @include font-family-bold();
        font-size: 1.25rem;
      }
    }
    .email-defined-footer {
      margin-top: 3rem;
    }
    .preview {
      margin-top: 3rem;
      margin-left: 0.5rem;
      user-select: none;
      width: 100%;
      font-size: 14px;
      overflow: hidden;
      flex-grow: 1;
      flex-shrink: 0;
      &.text {
        white-space: pre-line;
      }
    }
    .email-labeled {
      display: flex;
      margin-top: 1rem;

      .email-label {
        margin-right: 0.5rem;
        align-self: center;
        width: 5rem;
      }
    }
    .email-value {
      flex-grow: 1;
      .form-group input {
        margin-top: 0;
      }
    }
  }
}

.modal-icon {
  .icon, span.loader, span.loader > .icon {
    width: 5rem;
    height: 5rem;
    margin-bottom: 1rem;
  }
  span.loader {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.modal-title {
  font-size: 1.8rem;
  line-height: 2rem;
  letter-spacing: 0.075rem;
  margin-bottom: 1rem;
  max-width: 100%; // fix IE11 flexbox issue that allowed div to overflow the parent div
}
.confirm-delete {
  // added to modal-title to overide default modal-title behavior.
  max-width: 90%;
  text-align: center;
}

.modal-details {
  align-self: flex-start;
  cursor: pointer;
  :hover {
    color: blue;
  }
}

.modal-details-info {
  align-self: flex-start;
  overflow: auto;
  height: 100%;
  width: 100%;
}

.hide-details {
  visibility: hidden;
}

.modal-info {
  letter-spacing: 0.05rem;
  line-height: 1.4;
  max-width: 90%;
  text-align: center;
  white-space: pre-wrap;
}

.modal-additional {
  color: $gray-focused;
  font-size: 0.813rem;
  letter-spacing: 0.041rem;
  white-space: pre-wrap;
  max-width: 90%;
  text-align: center;
}

.modal-children {
  width: 100%
}

div.modal.critical div.modal-children {
  margin-top: 1rem;
}

.modal-buttons {
  margin-top: 1.25rem;
  .btn {
    border-radius: 0.25rem;
    height: 3.25rem;
    @include font-family-bold();
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    min-width: 10rem;
    margin-left: 1rem;
  }
  .btn:first-child {
    margin-left: 0;
    background-color: $gray-iron;
    color: $black;
  }
  .btn-primary {
    background-color: $blue-menu;
  }
}

.modal.report {
  .modal-icon .circleNotch {
    fill: $blue-dark;
    stroke-width: 0.1em;
    animation: spin 2s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .modal-icon .fileDownload {
    fill: none;
    stroke-width: 1rem;
  }
}

.modal.warning {
  .modal-icon .icon {
    fill: $orange;
    stroke: $orange;
  }
}

.modal.critical {
  .modal-icon .icon {
    fill: $red;
    stroke: $red;
  }
  .modal-info {
    text-align: inherit;
  }
}
