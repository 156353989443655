@import "../../scss/tsavariables";
@import "../../scss/tsamixins";
@import "~bootstrap/scss/mixins";

.client-search-box {
  @include font-family-light();
  background-color: $table-background-color;
  font-size: 0.9rem;
  left: 0;
  margin-left: 5rem;
  position: fixed;
  width: 60%;
  z-index: $search-box-z-index;

  form {
    flex: 1;
  }

  .form-inline{
    width: 33rem;
  }

  input.form-control {
    background-color: $table-background-color;
    border: 0;
    box-shadow: none;
    flex: 1;
    overflow-x: hidden;
    padding-right: 0px;
    width: 250px;
  }

  .icon {
    margin: 0 0.5rem;
    margin-bottom: 0.2rem;
    height: 1rem;
    width: 3rem;
    stroke: $gray;
    fill: $gray;
  }
}

@include media-breakpoint-up(sm) {
  .client-search-box {
    width: 50%;
  }
}

@include media-breakpoint-down(lg) {
  .client-search-box {
    top: 0.25rem;
  }
}

@include media-breakpoint-up(lg) {
  .client-search-box {
    margin-left: 6rem;
    margin-top: -0.5rem;
    width: 18rem;
    top: 1.2rem;
  }
}

@include media-breakpoint-up(xl) {
  .client-search-box {
    margin-left: 7rem;
    width: 20rem;
  }
}

.client-search-box-results {
  background-color: $table-background-color;
  border: 0.1rem solid $blue;
  border-radius: 5px;
  max-height: 90.5vh;
  margin-left: -5rem;
  overflow-y: auto;
  position: absolute;
  top: 2.5rem;
  width: 100vw;
  z-index: $top-nav-z-index;
}

.client-search-box-result {
  display: flex;
  align-items: flex-start;
  color: $blue;
  padding: 0.38rem 1rem;
  line-height: 1.2;
  cursor: pointer;
  .icon {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.5rem;
    fill: $blue;
    stroke: $blue;
  }
  .client-search-text {
    @include font-family-bold();
  }
  &:hover,
  &.selected {
    background: $blue;
    color: $white;
    .icon {
      fill: $white;
      stroke: $white;
    }
  }
}

.client-access-search-box-result {
  display: flex;
  align-items: flex-start;
  color: $blue;
  padding: 0.38rem 1rem;
  line-height: 1.2;
  background-color: #d7d7d7;
  font-size: medium;
}

@include media-breakpoint-up(sm) {
  .client-search-box-results {
    bottom: auto;
    left: 0;
    margin-left: 0;
    right: auto;
    top: 2.5rem;
    width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .client-search-box-results {
    width: 30rem;
  }
}

@include media-breakpoint-up(xl) {
  .client-search-box-results {
    width: 40rem;
  }
}
