@import '../../scss/tsavariables';
svg.circular-progress {
  stroke: $gray-taupe;
  fill: none;
  vertical-align: top;
  .circle-inner {
    stroke: #fff;
    fill: #fff;
  }
  .arc-outer {
    stroke-width: 0;
    fill: $gray-medium;
  }
  .circle-text {
    fill: $gray-dim;
  }
}
