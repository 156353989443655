@import "../../../scss/tsavariables";
@import "../../../scss/tsamixins";
$icon-size: 1.25rem;
.tsa-alert {
  display: flex;
  @include font-family-base();
  font-size: 0.813rem;
  line-height: 1.125;
  align-items: center;
  &.tsa-alert-error,
  &.tsa-alert-critical {
    color: $red;
    .tsa-alert-icon {
      fill: $red;
      stroke: $red;
    }
  }
  &.tsa-alert-success {
    color: $green;
    .tsa-alert-icon {
      fill: $green;
      stroke: $green;
    }
  }
  &.tsa-alert-warning {
    color: $orange;
    .tsa-alert-icon {
      fill: $orange;
      stroke: $orange;
    }
  }
}

.tsa-alert + .tsa-alert {
  margin-top: 0.4rem;
}

.tsa-alert-title {
  @include font-family-bold();
  &.general-type {
    text-transform: capitalize;
  }
}

.tsa-alert-text {
  display: inline-block;
  flex: 1;
}

.icon.tsa-alert-icon {
  height: $icon-size;
  width: $icon-size;
  min-width: $icon-size;
  margin-right: $icon-size / 2; // &.error {
}

.tsa-path-alerts {
  font-size: 0.813rem;
  a {
    margin-left: 1.875rem;
  }
}

.path-alert-link {
  color: $blue-dark;
  background: none;
  border: none;
  &:hover {
    color: $blue-medium;
    text-decoration: underline;
  }
}
