@import "../../scss/tsavariables";
@import "../../scss/tsamixins";
@import "~bootstrap/scss/mixins";
$second-to-last-opacity: 0.5;
$last-opacity: 0.25;
.datasheetHasLastYear.value {
  div {
    display: none;
  }
  &.canCollapse div {
    display: block;
  }
}

.selected .table-container {
  overflow-x: auto !important;
}

.link-datasheet-read-mode {
  a:hover {
    text-decoration: none;
  }
}

.table-container {
  overflow-x: hidden;
  position: relative;
  margin-top: 1rem;
  &::-webkit-scrollbar {
    height: 0.5rem;
    width: 7.5rem;
  }
}

.question-edit-form {
  .table-container {
    margin-top: 0;
  }
}

div {
  & * .data-grid-container {
    .data-grid-holder {
      .data-grid-content {
        .data-grid {
          margin-bottom: 0.75rem;
        }
      }
    }
    &:not(.read-only-datasheet) {
      .data-grid-holder {
        margin-bottom: 0.5rem;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        .data-grid-header {
          overflow: hidden;
          display: flex;
          flex-shrink: 0;
          table {
            background-color: $gray-medium;
            display: inline-block;
          }
        }
        .data-grid-content {
          flex-grow: 1;
          overflow: auto;
          &::-webkit-scrollbar {
            height: 0.5rem;
            width: 7.5rem;
          }
        }
      }
    }
    .data-grid {
      th {
        font-weight: normal;
      }
      tbody {
        border: 0.063rem solid $gray-medium;
      }
      .form-control {
        font-size: 0.75rem;
        padding: 0 0.5rem;
      }
      .react-select__control {
        border: 0;
      }
      .react-select__indicator-separator {
        display: none;
      }
      .react-select__indicators {
        background-color: $blue-light;
        svg {
          fill: $blue-selected-cell;
        }
      }
      .react-select input {
        height: 2.063rem !important;
      }
      .form-control,
      .react-select__value-container {
        color: $gray-black;
        background-color: $blue-selected-cell;
      }
      .cell {
        border: 0.063rem solid $gray-medium;
        color: $gray-dark;
        overflow-x: auto;
        overflow-y: hidden;
        height: auto;
        background-color: $white;
        white-space: pre-line;
        text-align: left;
        vertical-align: middle;
        word-break: break-word;
        font-size: 0.75rem;
        letter-spacing: 0.041rem;
        padding: 0 1rem 0 0.5rem;
        &.read-only {
          @include font-family-bold();
          word-break: normal;
          text-align: center;
          vertical-align: middle;
          border: none;
          font-size: 0.75rem;
          letter-spacing: 0.038rem;
          background-color: $gray-oslo;
          &.row-header,
          &.rows-header {
            border: 0.063rem solid $gray-medium;
            color: $white;
            padding: 0.8rem;
            background-color: $gray-medium;
            font-size: 0.875rem;
            min-width: 3.5rem;
            max-width: 3.5rem;
          }
          &.rows-header {
            border-right: 0.063rem solid $gray-taupe;
          }
          &.row-header.custom-header {
            font-size: 0.75rem;
            text-align: left;
            text-transform: uppercase;
          }
          &.content,
          &.column {
            border: 0.063rem solid $gray-taupe;
            min-width: 5rem;
            color: $white;
            padding: 0.8rem;
            background-color: $gray-medium;
            text-transform: uppercase;
          }
          &.empty {
            border: 0.063rem solid $gray-oslo;
          }
        }
        &.invalid {
          color: $gray;
          background-image: none;
        }
        &.selected {
          border: 0.063rem solid $gray-medium;
          box-shadow: unset;
        }
        &.editing {
          padding: 0;
          z-index: $top-nav-z-index;
          overflow: inherit;
          .form-group input,
          .form-group textarea {
            margin-top: 0;
            text-align: unset;
          }
          .form-group input,
          .form-group textarea {
            border: 0;
            box-shadow: none;
          }
        }
      } // IE/Edge/Firefox do not support break-word
      @supports (-ms-ime-align: auto) {
        .cell {
          word-break: break-all;
        }
      }
      @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        .cell {
          word-break: break-all;
        }
      }
      @-moz-document url-prefix() {
        .cell {
          word-break: break-all;
        }
      }
    }
    &.read-only-datasheet.uneditable .data-grid .cell {
      cursor: not-allowed;
    }
    &.read-only-datasheet:not(.uneditable) {
      .data-grid .cell {
        cursor: pointer;
      }
      &.truncated {
        .data-grid {
          tbody tr:nth-last-child(2) {
            opacity: $second-to-last-opacity;
            .cell {
              opacity: $second-to-last-opacity;
              border-color: rgba($gray-medium, $second-to-last-opacity);
              &.read-only {
                &.row-header,
                &.rows-header {
                  border-color: rgba($gray-medium, $second-to-last-opacity);
                }
                &.rows-header {
                  border-right-color: rgba(
                    $gray-taupe,
                    $second-to-last-opacity
                  );
                }
                &.content,
                &.column {
                  border-color: rgba($gray-taupe, $second-to-last-opacity);
                }
                &.empty {
                  border-color: rgba($gray-oslo, $second-to-last-opacity);
                }
              }
              &.selected {
                border-color: rgba($gray-medium, $second-to-last-opacity);
              }
            }
          }
          tbody tr:nth-last-child(1) {
            opacity: $last-opacity;
            .cell {
              opacity: $last-opacity;
              border-color: rgba($gray-medium, $last-opacity);
              &.read-only {
                &.row-header,
                &.rows-header {
                  border-color: rgba($gray-medium, $last-opacity);
                }
                &.rows-header {
                  border-right-color: rgba($gray-taupe, $last-opacity);
                }
                &.content,
                &.column {
                  border-color: rgba($gray-taupe, $last-opacity);
                }
                &.empty {
                  border-color: rgba($gray-oslo, $last-opacity);
                }
              }
              &.selected {
                border-color: rgba($gray-medium, $last-opacity);
              }
            }
          }
        }
      }
    }
  }
  &.selected * .data-grid-container:not(.uneditable) {
    .data-grid-holder {
      .data-grid-header {
        table {
          background-color: $blue-dark;
        }
      }
    }
    table.data-grid {
      border-collapse: separate;
    }
    .data-grid {
      tbody {
        border: 0.063rem solid $blue;
      }
      .cell {
        transition: color 500ms ease, background-color 500ms ease;
        border-color: $blue;
        border-left-width: 0;
        border-top-width: 0;
        position: relative;
        &.invalid {
          color: $red-error-grid;
          &::after {
            color: $red-error-grid;
            content: "!";
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 700;
            font-size: 1rem;
            position: absolute;
            top: 2px;
            right: 4px;
          }
        }
        &.read-only {
          border: 0.063rem solid $blue-border;
          background-color: $blue-border;
          &.row-header,
          &.rows-header {
            border: 0.063rem solid $blue-dark;
            color: $white;
            background-color: $blue-dark;
          }
          &.rows-header {
            border-right: 0.063rem solid $blue;
          }
          &.content,
          &.column {
            border: 0.063rem solid $blue;
            min-width: 5rem;
            padding: 0.8rem;
            color: $white;
            text-transform: uppercase;
            background-color: $blue-curious;
          }
          &.empty {
            border: 0.063rem solid $blue-border;
          }
          &.linking {
            background-color: mix($blue, $blue-curious);
          }
        }
        &.selected {
          // border: 0.063rem solid $blue;
          color: $black;
          background-color: $blue-selected-cell;
          transition: none;
        }
      }
      .cell.editing::after {
        content: "";
      }
    }
    &.read-only-datasheet {
      &.truncated {
        .data-grid {
          tbody tr:nth-last-child(2) {
            opacity: $second-to-last-opacity;
            .cell {
              opacity: $second-to-last-opacity;
              border-color: rgba($blue, $second-to-last-opacity);
              &.read-only {
                border-color: rgba($blue, $second-to-last-opacity);
                &.row-header,
                &.rows-header {
                  border-color: rgba($blue-dark, $second-to-last-opacity);
                }
                &.rows-header {
                  border-right-color: rgba($blue, $second-to-last-opacity);
                }
                &.content,
                &.column {
                  border-color: rgba($blue, $second-to-last-opacity);
                }
                &.empty {
                  border-color: rgba($blue, $second-to-last-opacity);
                }
              }
              &.selected {
                border-color: rgba($blue, $second-to-last-opacity);
              }
            }
          }
          tbody tr:nth-last-child(1) {
            opacity: $last-opacity;
            .cell {
              opacity: $last-opacity;
              border-color: rgba($blue, $last-opacity);
              &.read-only {
                border-color: rgba($blue, $last-opacity);
                &.row-header,
                &.rows-header {
                  border-color: rgba($blue-dark, $last-opacity);
                }
                &.rows-header {
                  border-right-color: rgba($blue, $last-opacity);
                }
                &.content,
                &.column {
                  border-color: rgba($blue, $last-opacity);
                }
                &.empty {
                  border-color: rgba($blue, $last-opacity);
                }
              }
              &.selected {
                border-color: rgba($blue, $last-opacity);
              }
            }
          }
        }
      }
    }
  }
}

.view-edit-datasheet-region {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  .view-edit-datasheet {
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    color: $white;
    width: 13.75rem;
    height: 3.188rem;
    text-align: center;
    background-color: $blue-menu;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:focus {
      outline: 1px solid $blue;
    }
  }
}

.calendarIcon {
  margin-bottom: -0.8rem;
}
