@import '../../scss/tsamixins';
@import '../../scss/tsavariables';
@import '~bootstrap/scss/mixins';

.pills {
  display: flex;

  .pill {
    margin-top: 1rem;
    margin-right: 1rem;
    border-radius: 1.5rem;
    background-color: $blue-disabled;
    color: $blue-dark;
    padding: 0.1rem 0.5rem;
    user-select: none;
    display: flex;

    .pill-label {
      margin-right: auto;
    }

    .pill-close {
      margin-left: 1.5rem;
      height: 1.5rem;
      width: 1.5rem;
      fill: $blue-dark;
      stroke: $blue-dark;
      stroke-width: 4rem;
      opacity: 1;
      cursor: pointer;
    }
  }
}
