@import "../../scss/tsamixins";
@import "../../scss/tsavariables";
@import "~bootstrap/scss/mixins";

.loading-indicator {
  padding: 1rem;
}

@include media-breakpoint-up(xl) {
  .loading-indicator {
    padding: 1rem 2rem;
  }
}

.engagement-header-no-section {
  height: 4rem;
}

.engagement-header {
  color: $white;
  background-color: $blue-dark;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  line-height: 1.25;
  flex-shrink: 0;
  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
  }
  @include media-breakpoint-up(xl) {
    padding: 1rem 2rem;
    font-size: 1.75rem;
  }
  a {
    color: $white;
  }
}

.engagement-header-section {
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 1rem;
  @include media-breakpoint-up(xl) {
    font-size: 1rem;
    line-height: inherit;
  }
}

.engagement {
  position: relative;
  height: 100%;
  overflow: hidden;
  button[name="sections"] {
    padding-left: 0;
  }
  .sections {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 0.0625rem solid $table-hover-color;
    padding-left: 0;
    padding-right: 0;
  }
  .engagement-content {
    transition: padding-left 300ms ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
}

.engagement-edit {
  height: 100%;
  max-height: 100%;
}

.engagement-dashboard {
  width: 100%;
  min-height: 100%;
  background-color: $white-smoke;
}
