@import '../../scss/tsamixins';
@import '../../scss/tsavariables';

.glossary-body {
  max-width: 60rem;
  line-height: 1rem;
  font-size: 0.9rem;
  padding: 2rem;
}

.download-990-glossary {
  @include font-family-bold();
  cursor: pointer;
  user-select: none;
  margin-top: 2rem;

  &.btn {
    border-radius: 0.5rem;
    padding: 0.5rem 1rem 0.75rem 1rem;
    display: flex;
    width: 11.1rem;
  }

  .text {
    padding-top: 0.25rem;
    text-transform: uppercase;
  }

  .icon {
    font-size: 0.4rem;
    fill: $white;
    margin-right: 0.5rem;
  }
}
