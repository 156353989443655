@import "../../scss/tsamixins";
@import "../../scss/tsavariables";
@import "~bootstrap/scss/mixins";

.engagements-list-header {
  .title {
    margin-top: 0.5rem;
  }
}

.engagements-list {
  height: 100%;
  .engagement-hidden {
    margin-left: 2rem;
  }
  @include media-breakpoint-down(lg) {
    .loading-indicator {
      margin-left: 1rem;
    }
  }
  .no-engagements {
    font-size: 2rem;
    font-style: italic;
    font-weight: bold;
    margin-left: 2rem;
  }
  .engagement-summary {
    opacity: 1;
    transition: all 0.4s ease-out;
    display: block;
    &.engagement-container-exit {
      opacity: 0;
    }
    &.engagement-container-exit-done {
      display: none;
    }
  }
}

.engagements-list-error {
  font-size: 2rem;
  margin-left: 2rem;
}
