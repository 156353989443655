@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
/* 
  Icon customizations. Anything in this file should be truly global. 
  Styles that a unique to a particular component should be contained
  in the component folder.
*/

.icon .icon-rectangle,
.icon.checkedCircle {
  fill: white;
  stroke: white;
}

.icon.ellipsis {
  fill: $white;
  stroke: $white;
}

.icon.carryForward,
.icon.circleBigCheck {
  stroke-width: 1em;
}

.icon.carryForwardAll {
  transform: scaleX(-1);
}

.icon.circleNotch {
  stroke-width: 1em;
}

.icon.cog {
  stroke-width: 0.5em;
}

.icon.na {
  background-color: $blue;
  border-radius: 50%;
  text-align: center;
  vertical-align: center;
  line-height: 3.25em;
  .text {
    @include font-family-bold();
    font-size: 1.5rem;
  }
}

.icon.saving,
.pulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.icon.notificationError,
.icon.notificationPending,
.icon.notificationSent {
  display: inline-block;
  position: relative;
  svg.badge {
    height: 75%;
    position: absolute;
    right: -35%;
    top: -10%;
    width: 75%;
    &.exclamationColor {
      height: 65%;
      width: 65%;
      right: -30%;
      top: -5%;
      fill: $red;
      stroke: transparent;
    }
  }
}
