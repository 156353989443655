@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
.engagement-review-summary {
  color: $gray;
}

.engagement-review-summary-item {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  span {
    margin-right: 0.25rem;
  }
  .icon {
    height: 1.25rem;
    width: 1.25rem;
    stroke: $green;
    fill: transparent;
    margin-right: 0.8rem;
  }
  &.complete .icon {
    fill: $green;
  }
  .title {
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }
  &.current-user .title {
    @include font-family-bold();
    font-size: 0.875rem;
  }
  .message {
    margin-left: 0.6rem;
    @include font-family-light();
  }
}
