@import "../../scss/tsavariables";
@import "../../scss/tsamixins";
.form-group {
  line-height: 1.125rem;
  label {
    display: block;
    @include font-family-light();
    letter-spacing: 0.05rem;
    margin-bottom: $label-margin-bottom;
  }
  .hint-text,
  .status-text {
    color: $gray;
    @include font-family-light();
    font-size: 0.688rem;
    letter-spacing: 0.034rem;
    margin-top: 0.5rem;
  }
  .status-text {
    margin-left: auto;
    padding-left: 0.5rem;
    white-space: nowrap;
    text-align: right;
  }
  .tsa-alert {
    margin-top: 0.5rem;
  }
  input,
  textarea {
    margin-top: 1rem;
  }
  .form-check-inline {
    margin-top: 1rem;
    input[type="radio"],
    input[type="checkbox"] {
      position: absolute;
      left: -99999rem;
    }
    label {
      display: inline-flex;
      align-items: center;
    }
  }
  .form-control.is-valid,
  .form-control.is-invalid,
  .form-control.is-valid:focus,
  .form-control.is-invalid:focus {
    border-color: $gray-dark;
    background-image: none;
  }
  .form-control:focus,
  .form-control.is-invalid:focus,
  .form-control.is-valid:focus {
    box-shadow: none;
  }
}

.selected {
  .form-control.is-valid,
  .form-control:focus.is-valid,
  .form-group.is-valid .react-select__control {
    border-color: $green;
  }
  .form-control.is-invalid,
  .form-control:focus.is-invalid,
  .form-group.is-invalid .react-select__control {
    border-color: $red;
  }
}

// Disabled state
.form-group.disabled {
  opacity: $input-disabled-opacity;
  .form-control {
    background-color: $body-bg;
    box-shadow: 0;
    border-color: $gray;
  }
}
