@import '../../scss/tsavariables';
@import '../../scss/tsamixins';
@import '~bootstrap/scss/mixins';
$transition: all 0.15s ease-in-out;
.doc-library-item-icons {
  .icon {
    height: 1.5rem;
    width: 1.5rem;
    stroke: $blue;
    fill: $blue;
    &.carryForward {
      fill: $white;
      &.active {
        fill: $blue;
      }
    }
    transition: $transition;
  }
  .icon.disabled {
    cursor: default;
    fill: #a6d1eb;
    svg {
      opacity: 0.4;
    }
  }
  .icon {
    &.carryForward,
    &.delete,
    &.edit,
    &.upload,
    &.download {
      display: none;
    }
  }
  .tooltip {
    max-width: 12rem;
  }
}

@include media-breakpoint-up(lg) {
  .doc-library-item-icons {
    .icon {
      &.carryForward,
      &.delete,
      &.edit,
      &.upload,
      &.download {
        display: inline;
      }
    }
    &.open {
      .icon {
        &.carryForward,
        &.delete,
        &.edit,
        &.upload,
        &.download {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}
