@import '../../scss/tsamixins';
@import '../../scss/tsavariables';
@import '~bootstrap/scss/mixins';

.engagements-list-header {
  background-color: $blue-dark;
  color: $white;
  font-family: $font-family-light;
  font-size: 2rem;
  padding: 1rem 2rem 1rem 2rem;

  .filters {
    display: flex;
    flex-direction: column;
    font-size: 0.813rem;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .filters-header {
      letter-spacing: 0.041rem;
      padding-bottom: 0.5rem;
    }

    .filters-drops {
      display: flex;
      flex: 1 1 auto;
      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }
      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      & > .sub-header-dropdown,
      & > .sub-header-dropdown-date-container {
        margin-right: 1.5rem;
        @include media-breakpoint-down(md) {
          padding-bottom: 0.5rem;
        }
        .btn {
          background-color: $blue-triangle-open;
          font-size: 0.813rem;
          line-height: 0.813rem;
          .btn-label {
            align-self: center;
          }
          .btn-toggle {
            padding-bottom: 1.0625rem;
          }
        }
        a.dropdown-item {
          font-size: 0.813rem;
        }
      }
    }
  }

  .sorts {
    display: flex;
    flex: 1 1 auto;
    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }
    .label {
      padding-bottom: 0.5rem;
    }
  }

  .title {
    flex-grow: 1;
  }

  .title ~ .sorts {
    flex-grow: 0;
  }

  &.has-filters {
    .title {
      margin-top: 1.2rem;
    }
  }

  .title-container {
    width: 100%;

    a {
      color: $white;
      font-size: 1rem;
    }

    .title-main-row {
      display: flex;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }
  }

  .engagements-list-header-pills-container {
    display: flex;
    font-size: 1rem;
    margin-top: 1rem;
    @media (max-width: 575px) {
      flex-direction: column;
    }

    .pills {
      @media (max-width: 575px) {
        flex-direction: column;
      }
    }

    .clear-all-filters {
      margin-top: 1rem;
      line-height: 2rem;
      font-size: 0.735rem;
      user-select: none;
      cursor: pointer;
    }
  }
}
