@import '../../scss/tsavariables';
@import '../../scss/tsamixins';

.form-group .react-select input {
  margin-top: 0;
}

.form-group .react-select .react-select__control {
  border-color: $gray-dark;
}

.data-grid .react-select {
  margin-top: 0;
}
