@import "../../scss/tsavariables";
@import "../../scss/tsamixins";
@import "~bootstrap/scss/mixins";
$question-icon-size: 1.8rem;
$question-icon-padding: 0.75rem;
$question-circle-size: 3.3rem;
$transition-background: background-color 200ms ease-in-out;
$transition-opacity: opacity 200ms ease-in-out;
$question-form-padding: 1rem;
$question-form-padding-xl: 2rem;
$question-selected-bar-width: 0.375rem;
$question-selected-bar-width-xl: 0.75rem;
.question {
  display: flex;
  line-height: 1.125rem;
  width: 100%;
  padding: 1.25rem 0;
  &.selected {
    background-color: $white-smoke;
  }
  &:hover:not(.disabled) .question-buttons {
    visibility: visible;
  }
  &.selected .question-buttons {
    visibility: visible;
  }
  &.disabled {
    .question-number,
    .question-label {
      opacity: 0.4;
    }
    .show,
    .use {
      color: $blue-disabled;
    }
  }
}

.question .question-form {
  flex-grow: 100;
  padding: 0 $question-form-padding - $question-selected-bar-width;
  width: 100%;
  @include media-breakpoint-up(xl) {
    min-width: 0rem;
    padding: 0 $question-form-padding-xl - $question-selected-bar-width-xl;
  }
}

.question-list .question {
  /* Define fixed, transparent borders so that we can turn on a color when
     selected or hovered and it doesn't change position on screen. */
  border-style: solid;
  border-color: transparent;
  border-width: 0 1px 0 $question-selected-bar-width;
  @include media-breakpoint-up(xl) {
    border-width: 0 1px 0 $question-selected-bar-width-xl;
  }
  border-right: 1px solid $green;
  &:hover {
    border-left-color: $blue-light;
  }
  &.selected {
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-color: $green;
    border-right-color: transparent;
  }
  &.selected.error {
    border-color: $red;
    border-right-color: transparent;
  }
}

.question-list.error .question {
  border-right-color: $red;
}

.question .question-number {
  @include font-family-bold();
}

.question .question-label {
  display: flex;
  @include font-family-light();
  letter-spacing: 0.05rem;
  justify-content: space-between;
  min-height: 1.25rem;
  label {
    margin-bottom: $label-margin-bottom;
  }
}

.question .question-na-clear {
  display: flex;
  align-items: baseline;
  margin-top: 1rem;
  .na-checkbox {
    margin-right: 1.5rem;
  }
  .simple-link {
    font-size: 0.688rem;
  }
}

.question .question-required-docs {
  color: $gray;
  font-size: 0.688rem;
  letter-spacing: 0.034rem;
  margin-top: 1.5rem;
}

.question .question-last {
  color: $gray;
  font-size: 0.813rem;
  margin-top: 0.5rem;
  .label {
    @include font-family-bold();
    display: inline-block;
    letter-spacing: 0.034rem;
    margin-right: 0.5rem;
    text-transform: uppercase;
  }
  .show {
    display: none;
    &.canCollapse,
    &.datasheetHasLastYear,
    &.hasMore {
      display: inline;
    }
  }
  .show,
  .use {
    @include font-family-light();
    background: none;
    border: none;
    color: $blue-dark;
  }
  .show:hover,
  .use:hover {
    color: $blue-dark;
    text-decoration: underline;
  }
  .value {
    @include font-family-italics();
    margin-right: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    &.canCollapse {
      overflow: visible;
      text-overflow: inherit;
      white-space: normal;
    }
  }
}

.question .question-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.question .question-footer-metadata {
  flex: 1;
  min-width: 0; // allow text to be truncated with ellipsis inside a flex container
}

.question .question-buttons {
  align-self: flex-end;
  display: none;
  visibility: hidden;
  padding: 0 0.5rem;
  transition: $transition-opacity;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.question .tickmark {
  text-transform: capitalize;
}

.question .question-icon-large.clickable {
  padding-bottom: 0.3rem;
}

.question .question-icon-large.circleBigCheck {
  display: none;
  margin-left: 0rem;
  margin-right: 1rem;
  height: 3.4rem;
  width: 3.4rem;
  stroke: rgba($green, 0.8);
  fill: transparent;
  &.active {
    fill: rgba($green, 0.8);
    &:hover:not(.cant-review) {
      fill: $green;
    }
  }
  &.disabled {
    cursor: default;
    opacity: 0.4;
    outline: none;
    stroke: $gray-icon;
    fill: $gray-icon;
  }
  &:hover {
    stroke: $green;
  }
  @include media-breakpoint-up(md) {
    display: block;
  }
}

.question div.question-icon-large {
  background-color: $blue;
  border-radius: $question-circle-size / 2;
  display: none;
  height: $question-circle-size;
  padding: $question-icon-padding;
  width: $question-circle-size;
  align-self: center;
  &:not(.active) {
    background-color: transparent !important;
    border: 0.1rem solid $blue !important;
    .icon {
      fill: $blue;
      stroke: $blue;
    }
  }
  @include media-breakpoint-up(md) {
    display: block;
  }
  cursor: pointer;
  .icon {
    height: $question-icon-size;
    width: $question-icon-size;
    stroke: $white;
    fill: $white;
  }
}

@include media-breakpoint-up(md) {
  div.question-icon-large {
    display: block;
  }
}

.question .question-other-files {
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  span {
    @include font-family-bold();
  }
}

.question-icons {
  display: inline-block;
  align-items: center;
  white-space: nowrap;
  .icon {
    vertical-align: baseline !important;
    margin-left: 0.5rem;
    height: 1rem;
    width: 1rem;
  }
  .icon.active {
    fill: $dark-gray-border;
    stroke: $white-smoke;
  }
  .icon.warning {
    fill: $orange;
    stroke: $orange;
  }
  .error-icon {
    fill: $red;
    stroke: $red;
  }
}

.question-alerts {
  min-height: 1.5rem;

  > *:not(:first-child) {
    margin-top: 0.5rem;
  }
}

.question-hint-text {
  color: $gray;
  @include font-family-light();
  font-size: 0.688rem;
  letter-spacing: 0.034rem;
  margin-top: 0.25rem;
}
