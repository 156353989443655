::-webkit-scrollbar {
  width: 0.5rem !important;
}

::-webkit-scrollbar-track {
  background: $table-hover-color;
}

::-webkit-scrollbar-thumb {
  background: $gray-iron;
}

::-webkit-scrollbar-thumb:hover {
  background: $gray-taupe;
}
